import { BookCorn, Contact, ContactDataSource, ContactRoleProducer } from '@eigtech/contacts-types'
import { useAssignContactToClaim } from '@eigtech/ui-shared-assignments'
import {
  Box,
  ComposedDrawerWrapperProps,
  Divider,
  Heading,
  SimpleGrid,
  Stack,
  WithDrawerErrorBoundaryProps,
  useToast,
  withDrawerErrorBoundary,
} from '@eigtech/ui-shared-dave'
import { FormDrawer } from '@eigtech/ui-shared-forms'
import { last } from 'lodash-es'
import { useCreateContact, useGetBook, useUpdateContact } from '../../../api'
import { AddressFieldArray } from './AddressField'
import { ContactForm, ContactFormInputField, useContactForm } from './ContactFormSchema'
import { EmailFieldArray } from './EmailField'
import { PhoneFieldArray } from './PhoneField'
import { RoleFieldArray } from './RoleField'
import {
  getDefaultRoleFromBookName,
  mapApiContactToFormContact,
  mapFormContactToApiContactRequest,
} from './utils'
import { getContactRoleLabel } from '../../../utils'

export type ContactFormDrawerProps = ComposedDrawerWrapperProps & {
  bookId?: BookCorn
  requiredRole?: ContactRoleProducer
} & (
    | { contact?: Partial<Contact>; dataSource: ContactDataSource }
    | { contact: Contact; dataSource?: undefined }
  )

export const ContactFormDrawer = withDrawerErrorBoundary(
  function ContactFormDrawer(props: ContactFormDrawerProps) {
    const { bookId, contact, dataSource, requiredRole: propsRequiredRole, ...drawerProps } = props

    const toast = useToast()

    const { data: book } = useGetBook(bookId ?? '')

    const form = useContactForm({
      defaultValues: mapApiContactToFormContact(contact ?? {}, book),
    })

    const { mutateAsync: createContact } = useCreateContact()
    const { mutateAsync: updateContact } = useUpdateContact()
    const { mutateAsync: assignContactToClaim } = useAssignContactToClaim()

    async function onSubmit(data: ContactForm) {
      const metadata = contact?.metadata
      const request = mapFormContactToApiContactRequest(data, metadata)

      const contactId = contact?.contactId

      let updatedContact: Contact | undefined

      if (!!contactId) {
        await updateContact({ ...request, contactId })
      } else {
        if (!dataSource) {
          throw new Error('Missing data source')
        }
        const { contactId } = await createContact({
          ...request,
          bookId,
          metadata: { dataSource },
        })

        updatedContact = {
          ...request,
          contactId,
        }
      }

      const claimNumber = book?.metadata.type === 'claim' && bookId && last(bookId.split(':'))

      const assignments: Promise<any>[] = []

      if (!!claimNumber && !!updatedContact) {
        if (request.roles.includes('primaryContact')) {
          assignments.push(
            assignContactToClaim({
              assigneeRelationship: 'primaryContact',
              claimNumber,
              contact: updatedContact,
            })
          )
        }

        if (request.roles.includes('policyHolder')) {
          assignments.push(
            assignContactToClaim({
              assigneeRelationship: 'policyHolder',
              claimNumber,
              contact: updatedContact,
            })
          )
        }
      }

      await Promise.all(assignments)

      toast({
        status: 'success',
        title: `Successfully ${!!contact?.contactId ? 'edited' : 'created'} contact!`,
      })
      props.onClose()
    }

    const bookName = book?.metadata.name ?? ''
    const requiredRole = propsRequiredRole || getDefaultRoleFromBookName(bookName ?? '')

    return (
      <FormDrawer {...drawerProps} {...baseProps(props)} form={form} onSubmit={onSubmit}>
        <Stack divider={<Divider />} px="2" spacing="6">
          <Box>
            <Heading mb="2" size="sm">
              Name
            </Heading>
            <SimpleGrid columns={2} spacing="4">
              <ContactFormInputField label="First Name" name="givenName" />
              <ContactFormInputField label="Last Name" name="familyName" />
            </SimpleGrid>
          </Box>

          <Box>
            <Heading mb="2" size="sm">
              Addresses
            </Heading>
            <AddressFieldArray />
          </Box>

          <Box>
            <Heading mb="2" size="sm">
              Contact Email(s)
            </Heading>
            <EmailFieldArray />
          </Box>

          <Box>
            <Heading mb="2" size="sm">
              Contact Phone Number(s)
            </Heading>
            <PhoneFieldArray />
          </Box>

          <Box>
            <Heading mb="2" size="sm">
              Other Info
            </Heading>

            <Stack spacing="4">
              <ContactFormInputField label="Description" name="description" />

              <RoleFieldArray requiredRole={requiredRole} />
            </Stack>
          </Box>
        </Stack>
      </FormDrawer>
    )
  },
  (props) => baseProps(props)
)

const baseProps = (props: ContactFormDrawerProps): WithDrawerErrorBoundaryProps => ({
  title: `${!!props.contact?.contactId ? 'Edit' : 'Create'} ${props.requiredRole ? `${getContactRoleLabel(props.requiredRole)} ` : ''}Contact`,
})
