import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema } from '../model/base.js'
import { LossOfUseSchema } from '../model/lossOfUse.js'
import { BaseHeaderSchema } from './base.js'

const AddLossOfUseSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: LossOfUseSchema.omit({
    lastModified: true,
    justification: true,
    status: true,
    id: true,
  }),
  headers: BaseHeaderSchema,
  response: LossOfUseSchema,
}

export const AddLossOfUseRequestSchema = AddLossOfUseSchemas.params
  .merge(AddLossOfUseSchemas.body)
  .merge(AddLossOfUseSchemas.headers)

const AddLossOfUseInputSchema = AddLossOfUseRequestSchema.merge(LossOfUseSchema)

export type AddLossOfUseInput = z.infer<typeof AddLossOfUseInputSchema>
export type AddLossOfUseBodyRequest = z.infer<typeof AddLossOfUseSchemas.body>
export type AddLossOfUseHeaderRequest = z.infer<typeof AddLossOfUseSchemas.headers>
export type AddLossOfUseRequest = z.infer<typeof AddLossOfUseRequestSchema>
export type AddLossOfUseResponse = z.infer<typeof AddLossOfUseSchemas.response>

export interface AddLossOfUseHandler extends Handler<AddLossOfUseRequest, AddLossOfUseResponse> {}

export const AddLossOfUseApiSchema: ApiSchema = AddLossOfUseSchemas
