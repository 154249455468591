import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import z from 'zod'

export const UpdateMeetingRequestBodySchema = z.object({
  description: z.string(),
})

export const UpdateMeetingRequestPathSchema = z.object({
  entityId: CornSchema,
  meetingId: z.string(),
  startTime: z.string().datetime(),
})

export const UpdateMeetingRequestHeaderSchema = ApiLambdalerAuthHeaderSchema

export const UpdateMeetingRequestSchema = UpdateMeetingRequestPathSchema.merge(
  UpdateMeetingRequestHeaderSchema
).merge(UpdateMeetingRequestBodySchema)

export type UpdateMeetingRequest = z.infer<typeof UpdateMeetingRequestSchema>
export type UpdateMeetingRequestBody = z.infer<typeof UpdateMeetingRequestBodySchema>
export type UpdateMeetingRequestPath = z.infer<typeof UpdateMeetingRequestPathSchema>
export type UpdateMeetingResponse = void

export type UpdateMeetingHandler = Handler<UpdateMeetingRequest, UpdateMeetingResponse>
