import { ContactCornSchema } from '@eigtech/contacts-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import {
  ClaimNumberSchema,
  InspectorRoleSchema,
  RequestedInspectionSchema,
} from '../model/index.js'
import { BaseHeaderSchema } from './base.js'

export const RequestClaimReInspectionApiSchema = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    requestedBy: ContactCornSchema.or(z.string()),
    inspectorRole: InspectorRoleSchema,
    notes: z.string().optional(),
  }),
  headers: BaseHeaderSchema,
  response: z
    .object({
      claimNumber: ClaimNumberSchema,
    })
    .merge(RequestedInspectionSchema),
}

export const RequestClaimReInspectionRequestSchema = RequestClaimReInspectionApiSchema.params
  .merge(RequestClaimReInspectionApiSchema.body)
  .merge(RequestClaimReInspectionApiSchema.headers)

export type RequestClaimReInspectionBodyRequest = z.infer<
  typeof RequestClaimReInspectionApiSchema.body
>
export type RequestClaimReInspectionHeaderRequest = z.infer<
  typeof RequestClaimReInspectionApiSchema.headers
>
export type RequestClaimReInspectionRequest = z.infer<typeof RequestClaimReInspectionRequestSchema>
export type RequestClaimReInspectionResponse = z.infer<
  typeof RequestClaimReInspectionApiSchema.response
>

export interface RequestClaimReInspectionHandler
  extends Handler<RequestClaimReInspectionRequest, RequestClaimReInspectionResponse> {}
