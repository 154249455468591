import z from 'zod'
import { JobExternalIdSchema } from './externalIds.js'
import { SubcontractorDocumentIdSchema } from './ids.js'

//TODO: Now that we have issued documents event, how should this enum expand?
export const SubcontractorDocumentKindSchema = z.enum(['invoice', 'photoReport'])
export const DocumentTransitMethodSchema = z.enum(['email', 'fax', 'mail', 'text', 'upload'])
export const SubcontractorDocumentSchema = z.object({
  subcontractorDocumentId: SubcontractorDocumentIdSchema,
  externalId: JobExternalIdSchema.optional(), // e.g. invoice ID
  kind: SubcontractorDocumentKindSchema.or(z.string()),
})

export const SubcontractorIssuedDocumentSchema = SubcontractorDocumentSchema.merge(
  z.object({
    issuedDate: z.string().datetime(),
    issuedMethod: DocumentTransitMethodSchema.or(z.string()),
  })
)
export const SubcontractorReceivedDocumentSchema = SubcontractorDocumentSchema.merge(
  z.object({
    issuedDate: z.string().datetime().optional(),
    issuedMethod: DocumentTransitMethodSchema.or(z.string()).optional(),
    receivedDate: z.string().datetime(),
    receivedMethod: DocumentTransitMethodSchema.or(z.string()),
  })
)
export type SubcontractorDocumentKind = z.infer<typeof SubcontractorDocumentKindSchema>
export type DocumentTransitMethod = z.infer<typeof DocumentTransitMethodSchema>
export type SubcontractorDocument = z.infer<typeof SubcontractorDocumentSchema>
export type SubcontractorIssuedDocument = z.infer<typeof SubcontractorIssuedDocumentSchema>
export type SubcontractorReceivedDocument = z.infer<typeof SubcontractorReceivedDocumentSchema>
