import { z } from 'zod'
import { BaseClaimSchema, MinimalClaimSchema } from '../model/base.js'
import { ClaimEventSchema, ClaimEventType, claimEventType } from './claim.js'

export const DateReceivedUpdatedEventSchema = ClaimEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.dateReceivedUpdated),
    claim: BaseClaimSchema.or(MinimalClaimSchema),
    metadata: ClaimEventSchema.shape.metadata.and(
      z.object({
        fromInAssignQueue: z.boolean().optional(),
      })
    ),
  })
)

export const MinimalDateReceivedUpdatedEventSchema = ClaimEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.dateReceivedUpdated),
    claim: MinimalClaimSchema.strict(),
    metadata: ClaimEventSchema.shape.metadata.and(
      z.object({
        fromInAssignQueue: z.boolean().optional(),
      })
    ),
  })
)

export type DateReceivedUpdatedEvent = z.infer<typeof DateReceivedUpdatedEventSchema>
