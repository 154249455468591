import { Book, ContactDataSourceSchema } from '@eigtech/contacts-types'
import { JobId } from '@eigtech/restoration-types'
import { Corn, getCornKernels } from '@eigtech/shared-corn'
import {
  getContactOfTypeFromEntity,
  useAssignContactToClaim,
  useAssignContactToJob,
  useGetAssignmentsForEntity,
} from '@eigtech/ui-shared-assignments'
import { ContextualCan } from '@eigtech/ui-shared-auth'
import { isClaimBook } from '@eigtech/ui-shared-claims'
import {
  ConfirmModal,
  DangerMenuItem,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Tooltip,
  chakra,
  useCopyToClipboard,
  useDisclosure,
  useToast,
} from '@eigtech/ui-shared-dave'
import { exhaustive } from 'exhaustive'
import { MdMoreHoriz } from 'react-icons/md'
import { useDeleteContact } from '../api'
import { useActivateContact } from '../api/activateContact'
import { useDeactivateContact } from '../api/deactivateContact'
import { AugmentedContact } from '../types'
import { EntityBookTypes, getBookEntityCorn, getContactRoleLabel, isEntityBook } from '../utils'
import { ViewContactModal } from './ContactDetail'
import { ContactFormDrawer } from './Forms'

export type ContactActionMenuProps = {
  book: Book | undefined
  contact: AugmentedContact
  menuItems?: (contact: AugmentedContact) => JSX.Element
}

export function ContactActionMenu({ book, contact, menuItems }: ContactActionMenuProps) {
  const { copyToClipboard } = useCopyToClipboard()

  const toast = useToast()

  const confirmSetPolicyholderModal = useDisclosure()
  const confirmSetPrimaryContactModal = useDisclosure()
  const confirmDeleteContactModal = useDisclosure()

  const {
    isOpen: isOpenContactModal,
    onOpen: onOpenContactModal,
    onClose: onCloseContactModal,
  } = useDisclosure()

  const contactFormDrawer = useDisclosure()

  const { mutateAsync: deleteContact, isPending: isDeletingContact } = useDeleteContact()
  const { mutateAsync: activateContact, isPending: isActivatingContact } = useActivateContact()
  const { mutateAsync: deactivateContact, isPending: isDeactivatingContact } =
    useDeactivateContact()

  async function handleDeleteContact() {
    try {
      await deleteContact({ contactId: contact.contactId })
      toast({
        status: 'success',
        title: 'Successfully deleted contact',
      })
    } catch (e) {
      toast({
        status: 'error',
        title: 'Failed to delete contact',
      })
      throw e
    }
  }

  async function handleContactInactiveStatus(inactive: boolean) {
    const status = inactive ? 'inactive' : 'active'

    try {
      if (inactive) {
        await deactivateContact({ contactId: contact.contactId })
      } else {
        await activateContact({ contactId: contact.contactId })
      }

      toast({
        status: 'success',
        title: `Contact is now ${inactive ? 'inactive' : 'active'}`,
      })
    } catch (e) {
      toast({
        status: 'error',
        title: `Failed to set contact as ${status}`,
      })
      throw e
    }
  }
  const entityCorn = !!book ? getBookEntityCorn(book, { shouldValidate: false }) : undefined

  const { data: assignments } = useGetAssignmentsForEntity(entityCorn ?? ('' as Corn))

  const primaryContact = getContactOfTypeFromEntity({
    assignments,
    contactType: 'primaryContact',
  })?.at(0)
  const policyholder = getContactOfTypeFromEntity({ assignments, contactType: 'policyHolder' })?.at(
    0
  )

  const isPrimaryContact = primaryContact?.contactId === contact.contactId
  const isPolicyholder = policyholder?.contactId === contact.contactId

  const { mutateAsync: assignContactToClaim, isPending: isAssigningContactToClaim } =
    useAssignContactToClaim()
  const { mutateAsync: assignContactToJob, isPending: isAssigningContactToJob } =
    useAssignContactToJob()

  async function handleAssignContactToClaim(
    assigneeRelationship: 'primaryContact' | 'policyHolder'
  ) {
    if (!book || !isEntityBook(book)) return

    const bookType = book.metadata.type as EntityBookTypes
    const { resourceId } = getCornKernels(book.bookId)

    if (!resourceId) return

    const sharedProps = {
      contact,
    } as const

    try {
      await exhaustive(bookType, {
        claim: () =>
          assignContactToClaim({
            ...sharedProps,
            assigneeRelationship,
            claimNumber: resourceId,
          }),
        job: () =>
          assignContactToJob({
            ...sharedProps,
            assigneeRelationship: 'primaryContact',
            jobId: resourceId as JobId,
          }),
      })

      toast({
        status: 'success',
        title: `Successfully set ${getContactRoleLabel(assigneeRelationship)}!`,
      })
    } catch (e) {
      toast({
        status: 'error',
        title: `Failed to set ${getContactRoleLabel(assigneeRelationship)}`,
      })
      throw e
    }
  }

  const isPending =
    isDeletingContact ||
    isAssigningContactToClaim ||
    isAssigningContactToJob ||
    isActivatingContact ||
    isDeactivatingContact

  return (
    <>
      <Menu computePositionOnMount>
        <MenuButton
          aria-label="Open contact menu"
          as={IconButton}
          icon={<Icon as={MdMoreHoriz} />}
          isLoading={isPending}
          size="sm"
        />
        <Portal>
          <MenuList>
            <>
              <MenuItem onClick={() => copyToClipboard(contact.contactId)}>
                Copy Contact ID
              </MenuItem>
              {!!contact.metadata.xactnetId && (
                <MenuItem onClick={() => copyToClipboard(String(contact.metadata.xactnetId))}>
                  Copy XactNet ID
                </MenuItem>
              )}

              <MenuItem onClick={onOpenContactModal}>View More Info</MenuItem>

              {!(contact.metadata.removed || contact.metadata.removedOn) && (
                <>
                  <ContextualCan I="update" a="claim">
                    {!!book && isEntityBook(book) && !isPrimaryContact && (
                      <MenuItem
                        isDisabled={isAssigningContactToClaim}
                        onClick={confirmSetPrimaryContactModal.onOpen}
                      >
                        Set as Primary Contact
                      </MenuItem>
                    )}

                    {/* Only allow setting policyholder if it's a claim book and contact is not policyholder */}
                    {!!book && isClaimBook(book) && !isPolicyholder && (
                      <MenuItem
                        isDisabled={isAssigningContactToClaim}
                        onClick={confirmSetPolicyholderModal.onOpen}
                      >
                        Set as Policyholder
                      </MenuItem>
                    )}
                  </ContextualCan>

                  <ContextualCan I="update" a="contact">
                    <MenuItem isDisabled={isPending} onClick={contactFormDrawer.onOpen}>
                      Edit Contact
                    </MenuItem>
                  </ContextualCan>

                  <ContextualCan I="update" a="contact">
                    <MenuItem
                      isDisabled={isPending}
                      onClick={() => handleContactInactiveStatus(!contact.metadata.inactive)}
                    >
                      Set as {contact.metadata.inactive ? 'Active' : 'Inactive'}
                    </MenuItem>
                  </ContextualCan>

                  {contactIsDeletable(contact) && (
                    <ContextualCan I="delete" a="contact">
                      <Tooltip
                        isDisabled={!(isPrimaryContact || isPolicyholder)}
                        label="Deleting primary contact or policyholder is not allowed"
                        placement="top"
                      >
                        <chakra.span w="full">
                          <DangerMenuItem
                            isDisabled={isPrimaryContact || isPolicyholder}
                            onClick={confirmDeleteContactModal.onOpen}
                          >
                            Delete Contact
                          </DangerMenuItem>
                        </chakra.span>
                      </Tooltip>
                    </ContextualCan>
                  )}
                </>
              )}

              {menuItems?.(contact)}
            </>
          </MenuList>
        </Portal>
      </Menu>

      {contactFormDrawer.isOpen && (
        <ContactFormDrawer {...contactFormDrawer} bookId={book?.bookId} contact={contact} />
      )}

      {isOpenContactModal && (
        <ViewContactModal
          contact={contact}
          isOpen={isOpenContactModal}
          onClose={onCloseContactModal}
        />
      )}

      {!!book && isClaimBook(book) && confirmSetPolicyholderModal.isOpen && (
        <ConfirmModal
          {...confirmSetPolicyholderModal}
          isPending={isPending}
          title="Set Policyholder"
          onConfirm={() => handleAssignContactToClaim('policyHolder')}
        >
          Are you sure you want to set this contact as the policyholder?
        </ConfirmModal>
      )}

      {!!book && isEntityBook(book) && confirmSetPrimaryContactModal.isOpen && (
        <ConfirmModal
          {...confirmSetPrimaryContactModal}
          isPending={isPending}
          title="Set Primary Contact"
          onConfirm={() => handleAssignContactToClaim('primaryContact')}
        >
          Are you sure you want to set this contact as the primary contact?
        </ConfirmModal>
      )}

      {contactIsDeletable(contact) && confirmDeleteContactModal.isOpen && (
        <ConfirmModal
          {...confirmDeleteContactModal}
          confirmLabel="Delete"
          confirmProps={{ colorScheme: 'red' }}
          isPending={isDeletingContact}
          title="Delete Contact"
          onConfirm={handleDeleteContact}
        >
          Are you sure you want to delete this contact?
        </ConfirmModal>
      )}
    </>
  )
}

const contactIsDeletable = (contact: AugmentedContact) =>
  ([ContactDataSourceSchema.Enum.CSR, ContactDataSourceSchema.Enum.Estimator] as string[]).includes(
    contact.metadata.dataSource
  )
