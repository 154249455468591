import {
  ClaimAssigneeRelationshipCanon,
  ContactAssignee,
  GetAssignmentsForAssignableResponse,
} from '@eigtech/assignments-types'
import { Book } from '@eigtech/contacts-types'
import { Corn } from '@eigtech/shared-corn'
import { ensureClaimCorn } from '@eigtech/shared-corn-helper'
import { last } from 'lodash-es'
import { useMemo } from 'react'
import { useGetAssignmentsForEntity } from './getAssignmentsForEntity'

type ClaimContactAssignee = Exclude<ClaimAssigneeRelationshipCanon, 'job'>

export type UseGetContactOfTypeFromClaimProps = { contactType: ClaimContactAssignee } & (
  | { claimNumber: string }
  | { book: Book | undefined }
)

export function useGetContactOfTypeFromClaim(props: UseGetContactOfTypeFromClaimProps) {
  const claimNumber =
    'claimNumber' in props
      ? props.claimNumber
      : (props.book?.metadata.type === 'claim' && last(props.book.bookId.split(':'))) || ''

  const claimCorn = ensureClaimCorn(claimNumber)

  return useGetContactOfTypeFromEntity({ contactType: props.contactType, entityCorn: claimCorn })
}

export type UseGetContactOfTypeFromEntityProps = {
  contactType: ClaimContactAssignee
  entityCorn: Corn
}

export function useGetContactOfTypeFromEntity({
  contactType,
  entityCorn,
}: UseGetContactOfTypeFromEntityProps) {
  const query = useGetAssignmentsForEntity(entityCorn)

  return useMemo(
    () => ({
      query,
      contacts: getContactOfTypeFromEntity({ assignments: query.data, contactType }),
    }),
    [query, contactType]
  )
}

export type GetContactOfTypeFromEntityProps = {
  assignments: GetAssignmentsForAssignableResponse | undefined
  contactType: ClaimContactAssignee
}

export function getContactOfTypeFromEntity({
  assignments,
  contactType,
}: GetContactOfTypeFromEntityProps) {
  return assignments
    ?.filter((assignment) => assignment.assignable.assigneeRelationship === contactType)
    .map((assignment) => (assignment.assignee as ContactAssignee).contact)
}
