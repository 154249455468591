import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema, InitialClaimActionsSchema } from '../model/index.js'
import { BaseHeaderSchema } from './base.js'

const RemoveInitialClaimActionsSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    initialClaimActions: InitialClaimActionsSchema.array(),
  }),
  headers: BaseHeaderSchema,
  response: z.object({
    claimNumber: ClaimNumberSchema,
    initialClaimActions: InitialClaimActionsSchema.array(),
  }),
}

export const RemoveInitialClaimActionsRequestSchema = RemoveInitialClaimActionsSchemas.params
  .merge(RemoveInitialClaimActionsSchemas.body)
  .merge(RemoveInitialClaimActionsSchemas.headers)

export type RemoveInitialClaimActionsBodyRequest = z.infer<
  typeof RemoveInitialClaimActionsSchemas.body
>
export type RemoveInitialClaimActionsHeaderRequest = z.infer<
  typeof RemoveInitialClaimActionsSchemas.headers
>
export type RemoveInitialClaimActionsRequest = z.infer<
  typeof RemoveInitialClaimActionsRequestSchema
>
export type RemoveInitialClaimActionsResponse = z.infer<
  typeof RemoveInitialClaimActionsSchemas.response
>

export interface RemoveInitialClaimActionsHandler
  extends Handler<RemoveInitialClaimActionsRequest, RemoveInitialClaimActionsResponse> {}

export const RemoveInitialClaimActionsApiSchema: ApiSchema = RemoveInitialClaimActionsSchemas
