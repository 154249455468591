import { Handler } from 'aws-lambda'
import z from 'zod'
import { VediImportFilterOptionsSchema, VediImportSchema } from '../vediImport.js'

export const RetrieveAllImportsApiSchema = {
  query: VediImportFilterOptionsSchema.merge(
    z.object({
      importStates: z.string().optional(),
      nextToken: z.string().optional(),
      limit: z.coerce.number().optional(),
    })
  ),
  response: z.object({
    nextToken: z.string().optional(),
    results: VediImportSchema.array(),
  }),
}

export type RetrieveAllImportsRequest = z.infer<typeof RetrieveAllImportsApiSchema.query>
export type RetrieveAllImportsResponse = z.infer<typeof RetrieveAllImportsApiSchema.response>
export type RetrieveAllImportsHandler = Handler<
  RetrieveAllImportsRequest,
  RetrieveAllImportsResponse
>
