import z from 'zod';
export const RecordBatchCompleteRequestSchema = z.object({
    executionId: z.string(),
    error: z.string().optional(),
    hasError: z.boolean(),
});
export const ReplayRecordSchema = z.object({
    executionId: z.string(),
    setupDetails: z.object({
        detachTime: z.number(),
        targetProjectorArn: z.string(),
        eventSourceMappingUUID: z.string(),
        manifestKey: z.string(),
        policyArn: z.string(),
        replayStreamArn: z.string(),
        replayUntil: z.number(),
        shardCount: z.number(),
        prevFilters: z.record(z.unknown()),
    }),
});
export const BatchRecordSchema = z.object({
    executionId: z.string(),
    currentTaskToken: z.string(),
    moreBatches: z.boolean(),
    windowStart: z.number().optional(),
    windowCursor: z.number(),
    awaitingConfirmation: z.number(),
    confirmationsReceived: z.number(),
});
