import { cornType } from '@eigtech/shared-corn'
import z from 'zod'

/** @deprecated */
export const claimResourceType = 'claim'
/** @deprecated */
export const claimsServiceCorn = <V extends string | undefined>(eventName?: V) =>
  cornType('claims', eventName)
/** @deprecated */
export const ClaimsServiceCornSchema = claimsServiceCorn()
/** @deprecated */
export type ClaimsServiceCorn = z.infer<typeof ClaimsServiceCornSchema>
