import {
  ErrorAlertEvent,
  ErrorAlertEventSchema,
  WarnAlertEvent,
  WarnAlertEventSchema,
} from '@eigtech/alerts-types'
import z, { discriminatedUnion } from 'zod'

export const StartCaptureFailedAlertEventSchema = ErrorAlertEventSchema.merge(
  z.object({
    type: z.literal('alert:error:startCaptureFailed'),
    metadata: z.object({
      service: z.literal('meetings'),
    }),
  })
)

export type StartCaptureFailedAlertEvent = z.infer<typeof StartCaptureFailedAlertEventSchema>

export type StopCaptureFailedAlertEvent = WarnAlertEvent & {
  type: `alert:warn:stopCaptureFailed`
  metadata: {
    service: 'meetings'
  }
}

export const StartConcatenationFailedAlertEventSchema = ErrorAlertEventSchema.merge(
  z.object({
    type: z.literal('alert:error:startConcatenationFailed'),
    metadata: z.object({
      service: z.literal('meetings'),
    }),
  })
)
export type StartConcatenationFailedAlertEvent = z.infer<
  typeof StartConcatenationFailedAlertEventSchema
>

export type StopConcatenationFailedAlertEvent = WarnAlertEvent & {
  type: `alert:warn:stopConcatenationFailed`
  metadata: {
    service: 'meetings'
  }
}

export const StartTranscriptionFailedAlertEventSchema = ErrorAlertEventSchema.merge(
  z.object({
    type: z.literal('alert:error:startTranscriptionFailed'),
    metadata: z.object({
      service: z.literal('meetings'),
    }),
  })
)
export type StartTranscriptionFailedAlertEvent = z.infer<
  typeof StartTranscriptionFailedAlertEventSchema
>

export const StartMediaFailedAlertEventSchema = discriminatedUnion('type', [
  StartCaptureFailedAlertEventSchema,
  StartConcatenationFailedAlertEventSchema,
  StartTranscriptionFailedAlertEventSchema,
])
export type StartMediaFailedAlertEvent = z.infer<typeof StartMediaFailedAlertEventSchema>

export type StopTranscriptionFailedAlertEvent = WarnAlertEvent & {
  type: `alert:warn:stopTranscriptionFailed`
  metadata: {
    service: 'meetings'
  }
}

export type StartMeetingFailedAlertEvent = ErrorAlertEvent & {
  type: `alert:error:startMeetingFailed`
  metadata: {
    service: 'meetings'
  }
}

export const EndMeetingFailedAlertEventSchema = WarnAlertEventSchema.merge(
  z.object({
    type: z.literal('alert:warn:endMeetingFailed'),
    metadata: z.object({
      service: z.literal('meetings'),
    }),
  })
)
export type EndMeetingFailedAlertEvent = z.infer<typeof EndMeetingFailedAlertEventSchema>

export type JoinMeetingFailedAlertEvent = ErrorAlertEvent & {
  type: `alert:error:joinMeetingFailed`
  metadata: {
    service: 'meetings'
  }
}

export type JoinMeetingAsGuestFailedAlertEvent = ErrorAlertEvent & {
  type: `alert:error:joinMeetingAsGuestFailed`
  metadata: {
    service: 'meetings'
  }
}

export type ListMeetingFailedAlertEvent = ErrorAlertEvent & {
  type: `alert:error:listMeetingFailed`
  metadata: {
    service: 'meetings'
  }
}

export type SendMeetingInviteFailedAlertEvent = ErrorAlertEvent & {
  type: `alert:error:sendMeetingInviteFailed`
  metadata: {
    service: 'meetings'
  }
}

export type SendMeetingInviteToInviteeFailedAlertEvent = ErrorAlertEvent & {
  type: `alert:error:sendMeetingToInviteeFailed`
  metadata: {
    service: 'meetings'
  }
}

export type MediaReactorFailedAlertEvent = ErrorAlertEvent & {
  type: `alert:error:mediaReactorFailed`
  metadata: {
    service: 'meetings'
  }
}

export type ProjectorFailedAlertEvent = ErrorAlertEvent & {
  type: `alert:error:projectorFailed`
  metadata: {
    service: 'meetings'
  }
}

export type CaptureRecoveryFailedAlertEvent = ErrorAlertEvent & {
  type: `alert:error:captureRecoveryFailed`
  metadata: {
    service: 'meetings'
  }
}

export type TranscriptPipelineFailedAlertEvent = ErrorAlertEvent & {
  type: `alert:error:transcriptPipelineFailed`
  metadata: {
    service: 'meetings'
  }
}

export type CheckStatusFailedAlertEvent = WarnAlertEvent & {
  type: `alert:warn:checkStatusFailed`
  metadata: {
    service: 'meetings'
  }
}

export type UpdateMeetingFailedAlertEvent = ErrorAlertEvent & {
  type: `alert:error:updateMeetingFailed`
  metadata: {
    service: 'meetings'
  }
}

export type UploadFailedAlertEvent = ErrorAlertEvent & {
  type: `alert:error:uploadFailed`
  metadata: {
    service: 'meetings'
  }
}

export type HandleUploadFailedAlertEvent = ErrorAlertEvent & {
  type: `alert:error:handleUploadFailed`
  metadata: {
    service: 'meetings'
  }
}

export type HandleTranscriptFailedAlertEvent = ErrorAlertEvent & {
  type: `alert:error:handleTranscriptFailed`
  metadata: {
    service: 'meetings'
  }
}
