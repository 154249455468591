import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema } from '../model/index.js'

export const UpdateDateOfLossSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    dateOfLoss: z.string().datetime(),
  }),
  headers: ApiLambdalerAuthHeaderSchema.pick({ requestActor: true }).required({
    requestActor: true,
  }),
  response: z.object({
    claimNumber: ClaimNumberSchema,
    lossDetails: z.object({
      dateOfLoss: z.string(),
    }),
  }),
}

export const UpdateDateOfLossRequestSchema = UpdateDateOfLossSchemas.params
  .merge(UpdateDateOfLossSchemas.body)
  .merge(UpdateDateOfLossSchemas.headers)

export type UpdateDateOfLossBodyRequest = z.infer<typeof UpdateDateOfLossSchemas.body>
export type UpdateDateOfLossHeaderRequest = z.infer<typeof UpdateDateOfLossSchemas.headers>
export type UpdateDateOfLossRequest = z.infer<typeof UpdateDateOfLossRequestSchema>
export type UpdateDateOfLossResponse = z.infer<typeof UpdateDateOfLossSchemas.response>

export interface UpdateDateOfLossHandler
  extends Handler<UpdateDateOfLossRequest, UpdateDateOfLossResponse> {}

export const UpdateDateOfLossApiSchema: ApiSchema = UpdateDateOfLossSchemas
