import { z } from 'zod'
import { EventBase } from '@eigtech/event-stream-types'
import { ClaimsServiceEventBaseSchema, claimsServiceEventType } from './service.js'
import { LossLocationSchema } from '../aggregates/lossLocation.js'

/** @deprecated */
export const lossLocationEntityName = 'lossLocation'

const lossLocationEventType = <V extends string | undefined>(eventName?: V) =>
  claimsServiceEventType(lossLocationEntityName, eventName)

/** @deprecated */
export const LossLocationEventSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: lossLocationEventType(),
    lossLocation: z.unknown(),
  })
)

/** @deprecated */
export const LossLocationCreatedEventSchema = LossLocationEventSchema.merge(
  z.object({
    type: lossLocationEventType('created'),
    lossLocation: LossLocationSchema,
  })
)

/** @deprecated */
export const LossLocationUpdatedEventSchema = LossLocationEventSchema.merge(
  z.object({
    type: lossLocationEventType('updated'),
    lossLocation: LossLocationSchema,
  })
)

/** @deprecated */
export const LossLocationDeletedEventSchema = LossLocationEventSchema.merge(
  z.object({
    type: lossLocationEventType('deleted'),
    lossLocation: z.object({
      id: LossLocationSchema.shape.id,
    }),
  })
)

/** @deprecated */
export const isLossLocationCreatedEvent = (event: EventBase): event is LossLocationCreatedEvent =>
  LossLocationCreatedEventSchema.safeParse(event).success
/** @deprecated */
export const isLossLocationUpdatedEvent = (event: EventBase): event is LossLocationUpdatedEvent =>
  LossLocationUpdatedEventSchema.safeParse(event).success
/** @deprecated */
export const isLossLocationDeletedEvent = (event: EventBase): event is LossLocationUpdatedEvent =>
  LossLocationDeletedEventSchema.safeParse(event).success

/** @deprecated */
export type LossLocationEvent = z.infer<typeof LossLocationEventSchema>
/** @deprecated */
export type LossLocationCreatedEvent = z.infer<typeof LossLocationCreatedEventSchema>
/** @deprecated */
export type LossLocationDeletedEvent = z.infer<typeof LossLocationDeletedEventSchema>
/** @deprecated */
export type LossLocationUpdatedEvent = z.infer<typeof LossLocationUpdatedEventSchema>
