import { z } from 'zod';
export const eventType = (domain, entity, eventName) => z
    .custom((val) => {
    const cpat = '\\w+[-\\w]*\\w+';
    const test = (pat, val) => new RegExp(`^${pat}$`, 'g').test(val);
    if ([domain, entity, eventName].some((c) => c !== undefined && !test(cpat, c)))
        return false;
    return test(`^${domain ?? cpat}:${entity ?? cpat}:${eventName ?? cpat}$`, val);
})
    .describe(`${domain ?? '*'}:${entity ?? '*'}:${eventName ?? '*'}`);
/**
 * ## EventBase
 *
 * All events entering the global event stream should extend this type
 *
 * | Property | Description |
 * |:---------|:------------|
 * | id | A globally unique Id for the event |
 * | type | Format: `[ServiceName]:[Entity]:[Event]` For example: `csr:claim:primaryContactIndentified` |
 * | partitionKey | The specific aggregate ID that the event applied to |
 * | version | The sequentially increasing integer for the event applied to the aggregate |
 * | timestamp | epoch value of when the event took place |
 * | schemaVersion | version of the specific event schema |
 * | metadata | A generic place for event metadata |
 */
export const EventBaseSchema = z.object({
    id: z.string(),
    type: eventType(),
    partitionKey: z.string(),
    version: z.number().or(z.literal('not-set')),
    timestamp: z.number(),
    schemaVersion: z.number(),
    metadata: z.record(z.string(), z.union([z.string(), z.number(), z.boolean()])),
});
export const SystemEventTypeSchema = eventType('system');
export const SystemEventSchema = EventBaseSchema.merge(z.object({
    type: SystemEventTypeSchema,
}));
export const isSystemEvent = (event) => SystemEventSchema.safeParse(event).success;
export const EventTriggerSchema = z.object({
    triggerType: z.enum(['command', 'event']),
    triggerName: z.string(),
});
// Replay
export const ReplayEventSchema = SystemEventSchema.merge(z.object({
    type: eventType('system', 'replay'),
}));
export const ReplayStartedEventSchema = ReplayEventSchema.merge(z.object({
    type: eventType('system', 'replay', 'replayStarted'),
    replay: z.object({
        token: z.string(),
        callbackPayload: z.string(),
        errorPayload: z.string().optional(),
    }),
}));
export const ReplayBatchCompletedEventSchema = ReplayEventSchema.merge(z.object({
    type: eventType('system', 'replay', 'replayBatchCompleted'),
    replay: z.object({
        lambdaFunctionArn: z.string(),
        executionId: z.string(),
    }),
}));
export const ReplayCompletedEventSchema = ReplayEventSchema.merge(z.object({
    type: eventType('system', 'replay', 'replayCompleted'),
    replay: z.object({
        token: z.string(),
        callbackPayload: z.string(),
        errorPayload: z.string().optional(),
    }),
}));
