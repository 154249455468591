import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema, BaseEstimateSchema, EstimateIdSchema } from '../model/index.js'
import { BaseHeaderSchema } from './base.js'

const ReceiveEstimateSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
    estimateId: EstimateIdSchema,
  }),
  body: BaseEstimateSchema.omit({ claimNumber: true, estimateId: true }),
  headers: BaseHeaderSchema,
}

export const ReceiveEstimateRequestSchema = ReceiveEstimateSchemas.params
  .merge(ReceiveEstimateSchemas.body)
  .merge(ReceiveEstimateSchemas.headers)

export type ReceiveEstimateHeaderRequest = z.infer<typeof ReceiveEstimateSchemas.headers>
export type ReceiveEstimateRequest = z.infer<typeof ReceiveEstimateRequestSchema>
export type ReceiveEstimateResponse = void

export interface ReceiveEstimateHandler
  extends Handler<ReceiveEstimateRequest, ReceiveEstimateResponse> {}

export const ReceiveEstimateApiSchema: ApiSchema = ReceiveEstimateSchemas
