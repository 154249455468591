import { ContactCornSchema } from '@eigtech/contacts-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import {
  CanceledInspectionSchema,
  ClaimNumberSchema,
  PublicationTargetsSchema,
} from '../model/index.js'
import { BaseHeaderSchema } from './base.js'

export const CancelClaimInspectionApiSchema = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    canceledBy: ContactCornSchema.or(z.string()),
    dateCanceled: z.string().datetime(),
    inspectionId: z.string(),
    notes: z.string().optional(),
    publicationTargets: PublicationTargetsSchema.array().optional(),
  }),
  headers: BaseHeaderSchema,
  response: z.object({ claimNumber: ClaimNumberSchema }).merge(CanceledInspectionSchema),
} satisfies ApiSchema

export const CancelClaimInspectionRequestSchema = CancelClaimInspectionApiSchema.params
  .merge(CancelClaimInspectionApiSchema.body)
  .merge(CancelClaimInspectionApiSchema.headers)

export type CancelClaimInspectionBodyRequest = z.infer<typeof CancelClaimInspectionApiSchema.body>
export type CancelClaimInspectionHeaderRequest = z.infer<
  typeof CancelClaimInspectionApiSchema.headers
>
export type CancelClaimInspectionRequest = z.infer<typeof CancelClaimInspectionRequestSchema>
export type CancelClaimInspectionResponse = z.infer<typeof CancelClaimInspectionApiSchema.response>

export interface CancelClaimInspectionHandler
  extends Handler<CancelClaimInspectionRequest, CancelClaimInspectionResponse> {}
