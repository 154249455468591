import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { PublicationTargetsSchema } from '../model/index.js'
import { ClaimNumberSchema } from '../model/base.js'
import { BaseHeaderSchema } from './base.js'

const MarkClaimForPublicationSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({ publicationTarget: PublicationTargetsSchema }),
  headers: BaseHeaderSchema,
}

export const MarkClaimForPublicationRequestSchema = MarkClaimForPublicationSchemas.params
  .merge(MarkClaimForPublicationSchemas.body)
  .merge(MarkClaimForPublicationSchemas.headers)

export type MarkClaimForPublicationBodyRequest = z.infer<typeof MarkClaimForPublicationSchemas.body>
export type MarkClaimForPublicationHeaderRequest = z.infer<
  typeof MarkClaimForPublicationSchemas.headers
>
export type MarkClaimForPublicationRequest = z.infer<typeof MarkClaimForPublicationRequestSchema>
export type MarkClaimForPublicationResponse = void

export interface MarkForPublicationHandler
  extends Handler<MarkClaimForPublicationRequest, MarkClaimForPublicationResponse> {}

export const MarkClaimForPublicationApiSchema: ApiSchema = MarkClaimForPublicationSchemas
