import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import {
  ClaimNumberSchema,
  ClosedOutcomeInternalReasonSchema,
  ClosedOutcomeReasonSchema,
  OutcomeSchema,
  ReopenedOutcomeSchema,
} from '../model/index.js'
import { BaseHeaderSchema } from './base.js'

const ReopenClaimSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: OutcomeSchema.omit({ isClosed: true }).merge(
    z.object({
      reason: ClosedOutcomeReasonSchema.or(z.string()).optional(),
    })
  ),
  headers: BaseHeaderSchema,
  response: z
    .object({
      claimNumber: ClaimNumberSchema,
    })
    .merge(ReopenedOutcomeSchema),
}

export const ReopenClaimRequestSchema = ReopenClaimSchemas.params
  .merge(ReopenClaimSchemas.body)
  .merge(ReopenClaimSchemas.headers)

export const ReopenClaimInputSchema = ReopenClaimRequestSchema.merge(
  z.object({
    reason: ClosedOutcomeReasonSchema.or(ClosedOutcomeInternalReasonSchema)
      .or(z.string())
      .optional(),
  })
)

export type ReopenClaimInput = z.infer<typeof ReopenClaimInputSchema>
export type ReopenClaimHeaderRequest = z.infer<typeof ReopenClaimSchemas.headers>
export type ReopenClaimBodyRequest = z.infer<typeof ReopenClaimSchemas.body>
export type ReopenClaimRequest = z.infer<typeof ReopenClaimRequestSchema>
export type ReopenClaimResponse = z.infer<typeof ReopenClaimSchemas.response>

export interface ReopenClaimHandler extends Handler<ReopenClaimRequest, ReopenClaimResponse> {}

export const ReopenClaimApiSchema: ApiSchema = ReopenClaimSchemas
