import { useGetAssignedClaims } from '@eigtech/ui-estimator/api/claims'
import { MobileIconLink } from '@eigtech/ui-estimator/components/Core'
import { Center, chakra, ComposedAlert, GridItem, SimpleGrid } from '@eigtech/ui-shared-dave'
import { getRouteApi } from '@eigtech/ui-shared-router'
import { IconType } from 'react-icons'
import { IoCalendarNumberOutline, IoCalendarOutline } from 'react-icons/io5'
import { MdOutlineNewReleases } from 'react-icons/md'
import { ROUTER_PATHS } from '../../../lib/constants'
import { ClaimsSearch } from '../../../routes/claims'
import { useFilteredClaims } from './hooks'
import { ClaimsList } from './MobileClaimsList'

export function MobileClaimsDashboard() {
  const { assignments } = getRouteApi(ROUTER_PATHS.claims.routeId).useSearch()

  const { data } = useGetAssignedClaims()

  const view = () => {
    if (!!assignments) {
      return <ClaimsList />
    }

    return null
  }

  return data?.length ? (
    (view() ?? (
      <SimpleGrid columns={[3, 4, 5, 6, 7]} spacing={['2', '4']}>
        <FilterView Icon={MdOutlineNewReleases} assignments="new">
          New Assignments
        </FilterView>
        <FilterView Icon={IoCalendarNumberOutline} assignments="today">
          Today&apos;s Assignments
        </FilterView>
        <FilterView Icon={IoCalendarOutline} assignments="all">
          All Assignments
        </FilterView>
      </SimpleGrid>
    ))
  ) : (
    <ComposedAlert
      alert={{
        title: 'No claims assigned to you.',
        description:
          'If this is your first time logging in, it can take a few minutes for your claims to populate. Try refreshing in a few minutes. If this is not your first time logging in and you should have claims assigned to you, please contact EIG so we can diagnose this issue.',
      }}
      status="warning"
    />
  )
}

type FilterViewProps = {
  assignments: ClaimsSearch['assignments']
  children: string
  Icon: IconType
}

function FilterView({ assignments, children, Icon }: FilterViewProps) {
  const claims = useFilteredClaims(assignments)

  return (
    <GridItem position="relative">
      {/* TODO: remove ts-ignore once this issue has been fixed
                https://github.com/TanStack/router/issues/1278  */}
      {/* @ts-ignore */}
      <MobileIconLink Icon={Icon} search={{ assignments }} to=".">
        {children}
      </MobileIconLink>

      <Center
        background="blue.300"
        borderRadius="full"
        h={claims.length >= 100 ? '9' : '6'}
        position="absolute"
        right="-1"
        top="-1"
        w={claims.length >= 100 ? '9' : '6'}
      >
        <chakra.span fontSize="xs" fontWeight="semibold">
          {claims.length}
        </chakra.span>
      </Center>
    </GridItem>
  )
}
