import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobAppointmentIdSchema, JobIdSchema } from '../../base/ids.js'

export const RecordAppointmentCompletedRequestBodySchema = z.object({
  appointmentId: JobAppointmentIdSchema,
  appointmentNotes: z.string().optional(),
})

export const RecordAppointmentCompletedRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export const RecordAppointmentCompletedRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const RecordAppointmentCompletedRequestSchema =
  RecordAppointmentCompletedRequestBodySchema.merge(
    RecordAppointmentCompletedRequestHeadersSchema
  ).merge(RecordAppointmentCompletedRequestPathSchema)
export const RecordAppointmentCompletedResponseSchema = z.void()
export const RecordAppointmentCompletedApiSchema: ApiSchema = {
  body: RecordAppointmentCompletedRequestBodySchema,
  headers: RecordAppointmentCompletedRequestHeadersSchema,
  params: RecordAppointmentCompletedRequestPathSchema,
}

export interface RecordAppointmentCompletedRequestBody
  extends z.infer<typeof RecordAppointmentCompletedRequestBodySchema> {}
export interface RecordAppointmentCompletedRequestPath
  extends z.infer<typeof RecordAppointmentCompletedRequestPathSchema> {}
export interface RecordAppointmentCompletedRequest
  extends z.infer<typeof RecordAppointmentCompletedRequestSchema> {}
export type RecordAppointmentCompletedResponse = z.infer<
  typeof RecordAppointmentCompletedResponseSchema
>
export interface RecordAppointmentCompletedHandler
  extends Handler<RecordAppointmentCompletedRequest, RecordAppointmentCompletedResponse> {}
