import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema, SerializableReadModelClaimSchema } from '../model/index.js'

export const GetClaimDetailsInternalSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  response: SerializableReadModelClaimSchema.partial(),
}

export const GetClaimDetailsSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  headers: ApiLambdalerAuthHeaderSchema.pick({ requestActor: true, requestToken: true }).required({
    requestActor: true,
    requestToken: true,
  }),
  response: SerializableReadModelClaimSchema.partial().merge(
    z.object({
      isReadOnly: z.boolean(),
    })
  ),
}

export const GetClaimDetailsInternalRequestSchema = GetClaimDetailsInternalSchemas.params
export const GetClaimDetailsRequestSchema = GetClaimDetailsSchemas.params.merge(
  GetClaimDetailsSchemas.headers
)
export const GetClaimDetailsResponseSchema = GetClaimDetailsSchemas.response

export type GetClaimDetailsRequest = z.infer<typeof GetClaimDetailsRequestSchema>
export type GetClaimDetailsInternalRequest = z.infer<typeof GetClaimDetailsInternalRequestSchema>
export type GetClaimDetailsResponse = z.infer<typeof GetClaimDetailsResponseSchema>

export type GetClaimDetailsHandler = Handler<GetClaimDetailsRequest, GetClaimDetailsResponse>
export type GetClaimDetailsHandlerInternal = Handler<
  GetClaimDetailsInternalRequest,
  GetClaimDetailsResponse
>

export const GetClaimDetailsApiSchema: ApiSchema = GetClaimDetailsSchemas
export const GetClaimDetailsInternalApiSchema: ApiSchema = GetClaimDetailsInternalSchemas
