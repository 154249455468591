import { z } from 'zod'
import { eventType, EventBaseSchema } from '@eigtech/event-stream-types'

/** @deprecated */
export const serviceName = 'claims'
/** @deprecated */
export const claimsServiceEventType = <U extends string | undefined, V extends string | undefined>(
  entity?: U,
  eventName?: V
) => eventType(serviceName, entity, eventName)

/** @deprecated */
export const ClaimsServiceEventBaseSchema = EventBaseSchema.merge(
  z.object({
    type: claimsServiceEventType(),
  })
)
/** @deprecated */
export type ClaimsServiceEventBase = z.infer<typeof ClaimsServiceEventBaseSchema>
