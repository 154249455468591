import { GetJobDetailsRequest } from '@eigtech/restoration-types'
import { useQueryClient } from '@eigtech/ui-shared-api'
import { jobsQueryKeys } from '@eigtech/ui-shared-jobs'

export function useInvalidateJobs() {
  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries({ queryKey: jobsQueryKeys.list() })
  }
}

export function useInvalidateJob() {
  const queryClient = useQueryClient()

  return (jobId: GetJobDetailsRequest['jobId']) => {
    queryClient.invalidateQueries({ queryKey: jobsQueryKeys.detail(jobId) })
  }
}
