import { cornType } from '@eigtech/shared-corn'
import { z } from 'zod'
import { ContactRoleConsumerSchema, contactsServiceCorn } from './contacts.js'

/**
 * WARNING!! Do not use outside of schemas!
 */
const globalBookCornCanon = <V extends GlobalBookResourceIdCanon | undefined>(resourceId?: V) =>
  cornType('contacts', 'book', resourceId)

/**
 * WARNING!! Do not use outside of schemas!
 */
const globalBookCornLax = <V extends GlobalBookResourceIdLax | undefined>(resourceId?: V) =>
  cornType('contacts', 'book', resourceId)

export const BookCornSchema = contactsServiceCorn('book')

export const BookTypeEnumSchema = z.enum(['claim', 'global', 'other', 'job'])

export const BookMetadataSchema = z
  .object({
    name: z.string(),
    type: BookTypeEnumSchema,
  })
  .passthrough()

export const BookSchema = z.object({
  bookId: BookCornSchema,
  metadata: BookMetadataSchema,
})

export const DeprecatedGlobalBookResourceIdSchema = z.enum(['fileReviewer'])
export const GlobalBookResourceIdProducerSchema = z.enum([
  'carrier',
  'csr',
  'deskAdjuster',
  'fieldAdjuster',
  'reviewer',
  'fieldTechnician',
  'subcontractor',
  'projectManager',
])

export const GlobalBookResourceIdLaxSchema = z.enum([
  ...GlobalBookResourceIdProducerSchema.options,
  ...DeprecatedGlobalBookResourceIdSchema.options,
])

export const GlobalBookResourceIdCanonSchema = z
  .enum([...GlobalBookResourceIdLaxSchema.options])
  .transform((resourceId) => {
    switch (resourceId) {
      case 'fileReviewer':
        return GlobalBookResourceIdProducerSchema.Enum.reviewer
      default:
        return resourceId
    }
  })

/**
 * WARNING!! The resource ID is NOT NECESSARILY an enum, any old string works!
 * Do not rely on this for enum enforcement!
 */
export const GlobalBookCornCanonSchema = globalBookCornCanon()

/**
 * WARNING!! The resource ID is NOT NECESSARILY an enum, any old string works!
 * Do not rely on this for enum enforcement!
 */
export const GlobalBookCornLaxSchema = globalBookCornLax()

export const GlobalBookCanonSchema = BookSchema.merge(
  z.object({
    metadata: BookSchema.shape.metadata.and(z.object({ type: z.literal('global') })),
    id: GlobalBookCornCanonSchema.or(z.string()),
  })
)
export const GlobalBookLaxSchema = BookSchema.merge(
  z.object({
    metadata: BookSchema.shape.metadata.and(z.object({ type: z.literal('global') })),
    id: GlobalBookCornCanonSchema.or(z.string()),
  })
)

export const GlobalRoleToBookMapSchema = z
  .object({ role: ContactRoleConsumerSchema, book: GlobalBookCornCanonSchema })
  .array()
export type BookTypeEnum = z.infer<typeof BookTypeEnumSchema>
export type BookCorn = z.infer<typeof BookCornSchema>
export type Book = z.infer<typeof BookSchema>

export type GlobalBookCanon = z.infer<typeof GlobalBookCanonSchema>
export type GlobalBookCornCanon = z.infer<typeof GlobalBookCornCanonSchema>
export type GlobalBookResourceIdCanon = z.infer<typeof GlobalBookResourceIdCanonSchema>

export type GlobalBookLax = z.infer<typeof GlobalBookLaxSchema>
export type GlobalBookCornLax = z.infer<typeof GlobalBookCornLaxSchema>
export type GlobalBookResourceIdLax = z.infer<typeof GlobalBookResourceIdLaxSchema>
export type GlobalRoleToBookMap = z.infer<typeof GlobalRoleToBookMapSchema>
