import z from 'zod'
import { JobAppointmentSchema } from './appointment.js'
import { SubcontractorDocumentSchema } from './document.js'
import { JobSchema } from './job.js'
import { SubcontractorSchema } from './subcontractor.js'
import { WorkAuthorizationSchema } from './workAuthorization.js'

export const JobDetailsAppointmentSchema = JobAppointmentSchema.merge(
  z.object({ isCompleted: z.boolean() })
)

export const JobDetailsSubcontractorDocumentSchema = z.object({
  subcontractorDocument: SubcontractorDocumentSchema,
  subcontractor: SubcontractorSchema,
})

export const JobDetailsSchema = JobSchema.and(
  z.object({
    appointments: JobDetailsAppointmentSchema.array().optional(),
    closedReason: z.string().optional(),
    isClosed: z.boolean(),
    subcontractorDocuments: JobDetailsSubcontractorDocumentSchema.array().optional(),
    workAuths: WorkAuthorizationSchema.array().optional(),
  })
)

export type JobDetails = z.infer<typeof JobDetailsSchema>
export type JobDetailsAppointment = z.infer<typeof JobDetailsAppointmentSchema>
export type JobDetailsSubcontractorDocument = z.infer<typeof JobDetailsSubcontractorDocumentSchema>
