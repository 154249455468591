import { z } from 'zod'
import { LossDetailsSchema } from '../../aggregates/lossDetails.js'
import { Handler } from 'aws-lambda'

// Create Loss Details
/** @deprecated */
export const CreateLossDetailsRequestBodySchema = LossDetailsSchema.omit({ id: true })

/** @deprecated */
export const CreateLossDetailsRequestSchema = CreateLossDetailsRequestBodySchema

/** @deprecated */
export const CreateLossDetailsResponseSchema = z.object({
  id: LossDetailsSchema.shape.id,
})

/** @deprecated */
export type CreateLossDetailsRequestBody = z.infer<typeof CreateLossDetailsRequestBodySchema>
/** @deprecated */
export type CreateLossDetailsRequest = z.infer<typeof CreateLossDetailsRequestSchema>
/** @deprecated */
export type CreateLossDetailsResponse = z.infer<typeof CreateLossDetailsResponseSchema>

export interface CreateLossDetailsHandler
  extends Handler<CreateLossDetailsRequest, CreateLossDetailsResponse> {}

// Update Loss Details
/** @deprecated */
export const UpdateLossDetailsRequestBodySchema = LossDetailsSchema.omit({
  id: true,
  claimNumber: true,
}).partial()

/** @deprecated */
export const UpdateLossDetailsRequestPathSchema = z.object({
  claimNumber: z.string(),
})

/** @deprecated */
export const UpdateLossDetailsRequestSchema = UpdateLossDetailsRequestBodySchema.merge(
  UpdateLossDetailsRequestPathSchema
)
/** @deprecated */
export const UpdateLossDetailsResponseSchema = z.void()

/** @deprecated */
export type UpdateLossDetailsRequest = z.infer<typeof UpdateLossDetailsRequestSchema>
/** @deprecated */
export type UpdateLossDetailsRequestBody = z.infer<typeof UpdateLossDetailsRequestBodySchema>
/** @deprecated */
export type UpdateLossDetailsRequestPath = z.infer<typeof UpdateLossDetailsRequestPathSchema>
/** @deprecated */
export type UpdateLossDetailsResponse = z.infer<typeof UpdateLossDetailsResponseSchema>

export interface UpdateLossDetailsHandler
  extends Handler<UpdateLossDetailsRequest, UpdateLossDetailsResponse> {}

// Delete Loss Details
/** @deprecated */
export const DeleteLossDetailsRequestBodySchema = z.void()

/** @deprecated */
export const DeleteLossDetailsRequestPathSchema = z.object({
  lossDetailsId: LossDetailsSchema.shape.id,
})

/** @deprecated */
export const DeleteLossDetailsRequestSchema = DeleteLossDetailsRequestPathSchema

/** @deprecated */
export const DeleteLossDetailsResponseSchema = z.void()

/** @deprecated */
export type DeleteLossDetailsRequestBody = z.infer<typeof DeleteLossDetailsRequestBodySchema>
/** @deprecated */
export type DeleteLossDetailsRequestPath = z.infer<typeof DeleteLossDetailsRequestPathSchema>
/** @deprecated */
export type DeleteLossDetailsRequest = z.infer<typeof DeleteLossDetailsRequestSchema>
/** @deprecated */
export type DeleteLossDetailsResponse = z.infer<typeof DeleteLossDetailsResponseSchema>

export interface DeleteLossDetailsHandler
  extends Handler<DeleteLossDetailsRequest, DeleteLossDetailsResponse> {}
