import z from 'zod'
import {
  AssignableType,
  AssignableTypeEnum,
  AssigneeTypeEnumSchema,
  AssignmentLaxSchema,
  ClaimAssigneeRelationshipLaxSchema,
  JobAssigneeRelationshipEnumSchema,
} from '../entities/assignment.js'
import { AssignmentsServiceEventBaseSchema, serviceEventType } from './service.js'

export const assignedEventType = <V extends AssignableType>(entityType?: V) =>
  serviceEventType(entityType, 'assigned')

export const EntityAssignedEventSchema = AssignmentsServiceEventBaseSchema.merge(
  z.object({
    type: assignedEventType(),
    metadata: z.discriminatedUnion('assignableType', [
      z.object({
        assignableType: z.literal(AssignableTypeEnum.claim),
        assigneeType: AssigneeTypeEnumSchema,
        assigneeRelationship: ClaimAssigneeRelationshipLaxSchema,
        requestActor: z.string(),
      }),
      z.object({
        assignableType: z.literal(AssignableTypeEnum.job),
        assigneeType: AssigneeTypeEnumSchema,
        assigneeRelationship: JobAssigneeRelationshipEnumSchema,
        requestActor: z.string(),
      }),
    ]),
    assignment: AssignmentLaxSchema,
  })
)

export type EntityAssignedEvent = z.infer<typeof EntityAssignedEventSchema>
