import {
  GetAssignmentsForAssignableResponse,
  JobAssigneeRelationship,
} from '@eigtech/assignments-types'
import { Contact } from '@eigtech/contacts-types'
import { ArrayElement } from '@eigtech/function-utils'
import { Job } from '@eigtech/restoration-types'
import { ensureJobCorn } from '@eigtech/restoration-util'
import { JobSummariesQueryResult } from '@eigtech/summarizer-types'
import { useApiInstanceContext, useMutation, useQueryClient } from '@eigtech/ui-shared-api'
import { getContactName } from '@eigtech/ui-shared-contacts'
import { minutesToMilliseconds } from '@eigtech/ui-shared-dates'
import { jobsQueryKeys, useInvalidateJob, useInvalidateJobs } from '@eigtech/ui-shared-jobs'
import log from '@eigtech/ui-shared-logging'
import { useCallback } from 'react'
import { assign } from './assign'
import { makeJobAssignment } from './assignEntityToJob'
import { assignmentsQueryKeys } from './constants'
import { useInvalidateAssignmentsForEntity } from './getAssignmentsForEntity'

export function useOptimisticallyUpdateAssignedJobContact() {
  const queryClient = useQueryClient()
  const invalidateJobSummaries = useInvalidateJobs()
  const invalidateJob = useInvalidateJob()
  const invalidateAssignmentsForJob = useInvalidateAssignmentsForEntity()

  const onError = useCallback(
    (error: unknown, { jobId }: { jobId: Job['jobId'] }) => {
      const jobCorn = ensureJobCorn(jobId)

      log.error('could not set job primary', { error })

      invalidateJobSummaries()
      invalidateJob(jobId)
      invalidateAssignmentsForJob(jobCorn)
    },
    [invalidateAssignmentsForJob, invalidateJob, invalidateJobSummaries]
  )

  const onSuccess = useCallback(
    async ({
      contact,
      jobId,
      type,
      unassign,
    }: {
      jobId: Job['jobId']
      contact: Contact | undefined
      type: JobAssigneeRelationship
      unassign?: Contact['contactId']
    }) => {
      const jobCorn = ensureJobCorn(jobId)

      const basicContact = !!contact
        ? {
            id: contact.contactId,
            name: getContactName(contact),
          }
        : undefined

      // update job detail
      const detailQueryKey = jobsQueryKeys.detail(jobId)
      await queryClient.cancelQueries({ queryKey: detailQueryKey })

      const previousJobDetail = queryClient.getQueryData<JobSummariesQueryResult>(detailQueryKey)

      if (!!(previousJobDetail && basicContact)) {
        const keys = {
          approver: 'approver',
          coordinator: 'coordinator',
          dispatcher: 'dispatcher',
          fieldTechnician: 'fieldTechnicians',
          primaryContact: 'primaryContact',
          projectManager: 'projectManager',
          subcontractor: 'subcontractors',
        } satisfies Record<JobAssigneeRelationship, keyof JobSummariesQueryResult>
        const key = keys[type]

        const previousContact = previousJobDetail[key]

        queryClient.setQueryData<JobSummariesQueryResult>(detailQueryKey, {
          ...previousJobDetail,
          [type]: Array.isArray(previousContact)
            ? [...previousContact, basicContact]
            : { ...previousContact, ...basicContact },
        })
      }

      const assignmentQueryKey = assignmentsQueryKeys.assignable.detail(jobCorn)

      await queryClient.cancelQueries({ queryKey: assignmentQueryKey })

      const previousAssignment =
        queryClient.getQueryData<GetAssignmentsForAssignableResponse>(assignmentQueryKey)

      if (!!previousAssignment) {
        const updatedAssignment = !unassign
          ? [...previousAssignment].filter((assignment) => {
              const isUnrelatedRelationship = assignment.assignable.assigneeRelationship !== type
              const isNotAssignee = assignment.assignee.assigneeId !== contact?.contactId
              return isUnrelatedRelationship || isNotAssignee
            })
          : ([...previousAssignment].filter((assignment) => {
              const assignableIsJob = assignment.assignable.type === 'job'
              const isUnrelatedRelationship = assignment.assignable.assigneeRelationship !== type
              const isNotUnassignedContact = assignment.assignee.assigneeId !== unassign

              return assignableIsJob && (isUnrelatedRelationship || isNotUnassignedContact)
            }) satisfies GetAssignmentsForAssignableResponse)

        if (!!contact) {
          const jobAssignment = {
            ...makeJobAssignment({
              assigneeRelationship: type,
              jobId,
              contact,
            }),
            assignmentDate: new Date().toISOString(),
            requestActor: 'user',
          } as ArrayElement<GetAssignmentsForAssignableResponse>
          updatedAssignment.push(jobAssignment)
        }

        queryClient.setQueryData<GetAssignmentsForAssignableResponse>(
          assignmentQueryKey,
          updatedAssignment
        )
      }

      // invalidate after a few seconds on success
      // (it can take a lil bit for events on backend to catch up)
      setTimeout(() => {
        invalidateAssignmentsForJob(jobCorn)
        invalidateJobSummaries()
        invalidateJob(jobId)
      }, minutesToMilliseconds(1))
    },
    [invalidateAssignmentsForJob, invalidateJob, invalidateJobSummaries, queryClient]
  )

  return { onError, onSuccess }
}

export type AssignContactToJobProps = {
  assigneeRelationship: JobAssigneeRelationship
  jobId: Job['jobId']
  contact: Contact
}

export function useAssignContactToJob() {
  const { post } = useApiInstanceContext()
  const { onError, onSuccess } = useOptimisticallyUpdateAssignedJobContact()

  return useMutation({
    mutationFn: (props: AssignContactToJobProps) => {
      return assign(post)(makeJobAssignment({ ...props }))
    },
    onSuccess(__, { assigneeRelationship, contact, jobId }) {
      onSuccess({
        jobId,
        type: assigneeRelationship,
        contact,
      })
    },
    onError,
  })
}
