import { z } from 'zod'
import { ClaimEventType } from '../events/claim.js'
import { EstimateEventType } from '../events/estimate.js'

// These are specific to key dates in the read model
export const KeyDateRelatedEventTypeSchema = z.enum([
  ClaimEventType.customerContactedEmail,
  ClaimEventType.customerContactedLvm,
  ClaimEventType.initialCustomerContactSuccess,
  ClaimEventType.inspectionScheduled,
  EstimateEventType.reviewed,
])

export type KeyDateRelatedEventTypeEnum = z.infer<typeof KeyDateRelatedEventTypeSchema>

export const KeyDateRelatedEventType = KeyDateRelatedEventTypeSchema.Enum

export const isKeyDateRelatedEvent = (
  eventType: string
): eventType is KeyDateRelatedEventTypeEnum =>
  KeyDateRelatedEventTypeSchema.safeParse(eventType).success
