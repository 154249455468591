import { ContactCornSchema } from '@eigtech/contacts-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import {
  ClaimNumberSchema,
  CompletedInspectionSchema,
  PublicationTargetsSchema,
} from '../model/index.js'
import { BaseHeaderSchema } from './base.js'

export const CompleteClaimInspectionApiSchema = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    completedBy: ContactCornSchema.or(z.string()),
    dateCompleted: z.string().datetime(),
    inspectionId: z.string(),
    notes: z.string().optional(),
    publicationTargets: PublicationTargetsSchema.array().optional(),
  }),
  headers: BaseHeaderSchema,
  response: z
    .object({
      claimNumber: ClaimNumberSchema,
    })
    .merge(CompletedInspectionSchema),
} satisfies ApiSchema

export const CompleteClaimInspectionRequestSchema = CompleteClaimInspectionApiSchema.params
  .merge(CompleteClaimInspectionApiSchema.body)
  .merge(CompleteClaimInspectionApiSchema.headers)

export type CompleteClaimInspectionBodyRequest = z.infer<
  typeof CompleteClaimInspectionApiSchema.body
>
export type CompleteClaimInspectionHeaderRequest = z.infer<
  typeof CompleteClaimInspectionApiSchema.headers
>
export type CompleteClaimInspectionRequest = z.infer<typeof CompleteClaimInspectionRequestSchema>
export type CompleteClaimInspectionResponse = z.infer<
  typeof CompleteClaimInspectionApiSchema.response
>

export interface CompleteClaimInspectionHandler
  extends Handler<CompleteClaimInspectionRequest, CompleteClaimInspectionResponse> {}
