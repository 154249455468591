import { Timeline as TimelineType } from '@eigtech/summarizer-types'
import { useUserContext } from '@eigtech/ui-shared-auth'
import { useDatesContext } from '@eigtech/ui-shared-dates'
import {
  Badge,
  Box,
  Center,
  ComposedAlert,
  Divider,
  HStack,
  Skeleton,
  Stack,
  StackProps,
  Text,
  chakra,
  forwardRef,
} from '@eigtech/ui-shared-dave'
import { VediImportStateEnum, VediImportStateSchema } from '@eigtech/verisk-edi-interface-types'
import { range } from 'lodash-es'
import { Fragment, useMemo } from 'react'
import { z } from 'zod'
import { makeTimelinePointComparator } from '../utils/comparator'
import { getCreatedByLabel } from '../utils/getCreatedByLabel'

export type TimelineProps = {
  timeline: TimelineType | undefined
  isPending?: boolean
  isError?: boolean
} & StackProps

export const Timeline = forwardRef<TimelineProps, 'div'>(
  ({ timeline, isPending, isError, ...props }, ref) => {
    const sortedTimeline = useMemo(() => {
      return (timeline?.chronicles ?? []).sort(makeTimelinePointComparator(true))
    }, [timeline?.chronicles])

    const { PreferredDateTimeComponent: DateComponent } = useDatesContext()
    const { PreferredUserComponent: UserComponent } = useUserContext()

    return (
      <Stack ref={ref} {...props} spacing="0.5">
        {isPending ? (
          range(0, 3).map((i) => <Skeleton key={i} h="10" />)
        ) : isError ? (
          <ComposedAlert alert="Could not retrieve timeline information." status="error" />
        ) : (
          sortedTimeline.map(({ type, label, date, createdBy, details }, index) => {
            const createdByLabel = !!createdBy && getCreatedByLabel(createdBy)

            const xaStatus =
              !!details &&
              'xaStatus' in details &&
              VediImportStateSchema.safeParse(details.xaStatus).success
                ? (details.xaStatus as VediImportStateEnum)
                : undefined

            return (
              <Fragment key={`${type}${date}${index}`}>
                <HStack spacing="6">
                  <chakra.div
                    borderRadius="full"
                    h="6"
                    outline="1px solid"
                    outlineColor="brandPrimary.500"
                    outlineOffset="2px"
                    w="6"
                    {...(index === 0 ? { background: 'brandPrimary.500' } : {})}
                  />

                  <Box lineHeight={1.3}>
                    <HStack spacing="4">
                      <Text>{label}</Text>

                      {!!xaStatus && <Badge colorScheme="blue">XA - {xaStatus}</Badge>}
                    </HStack>

                    <Text textStyle="overline">
                      <DateComponent property="date">{date}</DateComponent>
                    </Text>

                    {!!details && <Details details={details} type={type} />}

                    {!!createdByLabel && (
                      <UserComponent property="createdBy" user={createdByLabel} />
                    )}
                  </Box>
                </HStack>

                {index !== sortedTimeline.length - 1 && (
                  <Center w="6">
                    <Divider h="8" ml="-1px" orientation="vertical" />
                  </Center>
                )}
              </Fragment>
            )
          })
        )}
      </Stack>
    )
  }
)

//

// #region - timeline dates
type DetailsProps = {
  details: Record<string, unknown>
  type: string
}

function Details({ details }: DetailsProps) {
  if (isScheduledDetailsSchema(details)) {
    return <ScheduledDetails details={details} />
  }

  return null
}

function ScheduledDetails({ details }: { details: ScheduledDetailsType }) {
  const { PreferredDateTimeComponent: DateComponent } = useDatesContext()
  return (
    <Stack as={Text} lineHeight={1.1} py="1" textStyle="overline">
      <chakra.span fontSize="0.6875rem">Scheduled for:</chakra.span>{' '}
      <DateComponent property="date">{details.scheduledDate}</DateComponent>
    </Stack>
  )
}

const ScheduledDetailsSchema = z.object({
  scheduledDate: z.string(),
})
type ScheduledDetailsType = z.infer<typeof ScheduledDetailsSchema>
const isScheduledDetailsSchema = (value: any): value is ScheduledDetailsType =>
  ScheduledDetailsSchema.safeParse(value).success
// #endregion
