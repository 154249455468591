import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import {
  BaseClaimSchema,
  ClaimDataSource,
  ClaimDataSourceSchema,
  ClaimNumberSchema,
} from '../model/index.js'
import { BaseHeaderSchema } from './base.js'

export const UpdateDateReceivedApiSchema = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: BaseClaimSchema.pick({
    dateReceived: true,
  }).merge(
    z.object({
      dataSource: ClaimDataSourceSchema.optional(),
      fromInAssignQueue: z.boolean(),
    })
  ),
  headers: BaseHeaderSchema,
  response: BaseClaimSchema.pick({
    claimNumber: true,
    dateReceived: true,
  }),
} satisfies ApiSchema

export const UpdateDateReceivedRequestSchema = UpdateDateReceivedApiSchema.params
  .merge(UpdateDateReceivedApiSchema.body)
  .merge(UpdateDateReceivedApiSchema.headers)

export type UpdateDateReceivedBodyRequest = z.infer<typeof UpdateDateReceivedApiSchema.body>
export type UpdateDateReceivedHeaderRequest = z.infer<typeof UpdateDateReceivedApiSchema.headers>
export type UpdateDateReceivedRequest = z.infer<typeof UpdateDateReceivedRequestSchema>
export type UpdateDateReceivedRequestInput = UpdateDateReceivedRequest & {
  dataSource: ClaimDataSource
}
export type UpdateDateReceivedResponse = z.infer<typeof UpdateDateReceivedApiSchema.response>

export interface UpdateDateReceivedHandler
  extends Handler<UpdateDateReceivedRequest, UpdateDateReceivedResponse> {}
