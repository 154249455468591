import {
  Contact,
  ContactCommunicationInformation,
  TelecommunicationEquipmentTypeSchema,
} from '@eigtech/contacts-types'
import { isDefined } from '@eigtech/function-utils'
import { isInEffect } from '../utils/inEffect.js'
import { isAnEmail, hasLowerPreferenceOrder } from './helpers.js'

export const getContactEmails = (contact: Partial<Contact>): string[] | undefined => {
  if (!contact?.communicationInformation?.length) {
    return undefined
  }

  return contact.communicationInformation
    .filter(
      (comm) =>
        comm.telecommunicationEquipmentType === TelecommunicationEquipmentTypeSchema.Enum.Email ||
        comm.emailAddress !== undefined
    )
    .map((email) => email.emailAddress)
    .filter(isDefined)
}

export const getContactPrimaryEmail = (
  contact: Partial<Contact>
): ContactCommunicationInformation | undefined => {
  const potentialEmails = contact.communicationInformation?.filter(
    (comm) => isAnEmail(comm) && isInEffect(comm)
  )

  if (!potentialEmails?.length) {
    return undefined
  }

  const preferredEmail = potentialEmails.reduce(
    (prev, cur) => {
      // Prefer lower preference order
      if (hasLowerPreferenceOrder(cur, prev)) {
        return cur
      }

      // Prefer a defined preferenceOrder
      if (prev.preferenceOrder === undefined && cur.preferenceOrder !== undefined) {
        return cur
      }

      return prev
    },
    potentialEmails[0] // Initialize to first candidate
  )

  return preferredEmail
}

export const getContactEmailAddress = (contact: Partial<Contact>): string | undefined => {
  const primaryEmail = getContactPrimaryEmail(contact)
  return primaryEmail?.emailAddress
}
