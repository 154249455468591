import { MessagePreview } from '@eigtech/inbox-types'
import {
  STANDARD_DATE_FORMAT,
  STANDARD_TIME_FORMAT,
  isToday,
  useDatesContext,
} from '@eigtech/ui-shared-dates'
import {
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  ComposedAlert,
  ComposedCard,
  FormControl,
  FormLabel,
  HStack,
  List,
  ListItem,
  Select,
  Skeleton,
  Stack,
  Text,
  useToast,
} from '@eigtech/ui-shared-dave'
import { useNavigate, useRoutesContext } from '@eigtech/ui-shared-router'
import { range } from 'lodash-es'
import { Dispatch, Fragment, SetStateAction, useEffect, useRef, useState } from 'react'
import {
  useArchiveMessages,
  useGetMessages,
  useMarkMessagesAsRead,
  useMarkMessagesAsUnread,
} from '../api'
import { From } from './Shared'
import { exhaustive } from 'exhaustive'

export type InboxMessagesListProps = {
  activeMessageId?: string
}

export function InboxMessagesList({ activeMessageId }: InboxMessagesListProps) {
  const [sortBy, setSortBy] = useState<'date' | 'read'>('date')

  const {
    data,
    isPending: isPendingMessages,
    isFetchingNextPage,
    isError: isMessagesError,
    hasNextPage,
    fetchNextPage,
  } = useGetMessages({ sortByRead: sortBy === 'read' ? 'true' : undefined })

  const [selected, setSelected] = useState(new Set<string>())

  return (
    <Stack flexShrink={0} spacing="4">
      <HStack>
        <FormControl>
          <FormLabel>Sort By</FormLabel>
          <Select
            defaultValue={sortBy}
            onChange={(e) => {
              setSortBy(e.target.value as typeof sortBy)
            }}
          >
            <option value="date">Date</option>
            <option value="read">Unread</option>
          </Select>
        </FormControl>
      </HStack>

      <BulkActions data={data} selected={selected} setSelected={setSelected} />

      <Stack flexShrink={0} maxH="calc(100vh - 19rem)" overflow="auto" pr="2" spacing="4">
        <List spacing="2">
          {isPendingMessages ? (
            range(0, 3).map((i) => <Skeleton key={i} h="20" w="80" />)
          ) : isMessagesError ? (
            <ComposedAlert alert="Could not load messages" status="error" w="80" />
          ) : data?.pages.length ? (
            data.pages.map((page, index) => (
              <Fragment key={index}>
                {page.messages.map((message) => (
                  <Message
                    key={message.messageId}
                    activeMessageId={activeMessageId}
                    message={message}
                    selected={selected}
                    setSelected={setSelected}
                  />
                ))}
              </Fragment>
            ))
          ) : (
            <ComposedAlert alert="No messages yet." status="info" w="80" />
          )}
        </List>

        {hasNextPage && (
          <Center>
            <Button
              isLoading={isPendingMessages || isFetchingNextPage}
              size="sm"
              onClick={() => fetchNextPage()}
            >
              Load More
            </Button>
          </Center>
        )}
      </Stack>
    </Stack>
  )
}

function BulkActions({
  data,
  selected,
  setSelected,
}: {
  data: ReturnType<typeof useGetMessages>['data']
  selected: Set<string>
  setSelected: Dispatch<SetStateAction<Set<string>>>
}) {
  const messages = (data?.pages ?? []).flatMap(({ messages }) => messages)
  const allChecked = selected.size === messages.length
  const isIndeterminate = selected.size > 0 && !allChecked

  const toast = useToast()

  const {
    mutateAsync: markMessagesAsRead,
    isPending: isMarkingAsRead,
    isError: isMarkAsReadError,
  } = useMarkMessagesAsRead()

  const {
    mutateAsync: markMessagesAsUnread,
    isPending: isMarkingAsUnread,
    isError: isMarkAsUnreadError,
  } = useMarkMessagesAsUnread()

  const {
    mutateAsync: archiveMessages,
    isPending: isArchiving,
    isError: isArchiveError,
  } = useArchiveMessages()

  async function handleBulkAction(type: 'read' | 'unread' | 'archive') {
    await exhaustive(type, {
      read: () => markMessagesAsRead({ messageIds: [...selected] }),
      unread: () => markMessagesAsUnread({ messageIds: [...selected] }),
      archive: () => archiveMessages({ messageIds: [...selected] }),
    })

    setSelected(new Set())

    toast({
      status: 'success',
      title: `Successfully updated selected messages (${type})`,
    })
  }

  return (
    <Stack>
      <HStack justifyContent="space-between">
        <Checkbox
          isChecked={allChecked}
          isDisabled={!messages.length}
          isIndeterminate={isIndeterminate}
          size="lg"
          sx={{
            '.chakra-checkbox__input:not(:checked) ~ .chakra-checkbox__control:not([data-indeterminate])':
              {
                bg: 'white',
              },
          }}
          onChange={(e) => {
            if (!e.target.checked) {
              setSelected(new Set())
              return
            }

            const messageIds = (data?.pages ?? [])
              .flatMap(({ messages }) => messages)
              .map(({ messageId }) => messageId)

            setSelected(new Set(messageIds))
          }}
        />

        <ButtonGroup
          isAttached
          isDisabled={!selected.size || isMarkingAsRead || isMarkingAsUnread || isArchiving}
          size="xs"
          variant="outline"
        >
          <Button isLoading={isMarkingAsRead} onClick={() => handleBulkAction('read')}>
            Mark Read
          </Button>
          <Button isLoading={isMarkingAsUnread} onClick={() => handleBulkAction('unread')}>
            Mark Unread
          </Button>
          <Button isLoading={isArchiving} onClick={() => handleBulkAction('archive')}>
            Archive
          </Button>
        </ButtonGroup>
      </HStack>

      {isMarkAsReadError && (
        <ComposedAlert alert="Could not mark selected messages as read" isClosable status="error" />
      )}
      {isMarkAsUnreadError && (
        <ComposedAlert
          alert="Could not mark selected messages as unread"
          isClosable
          status="error"
        />
      )}
      {isArchiveError && (
        <ComposedAlert alert="Could not archive selected messages" isClosable status="error" />
      )}
    </Stack>
  )
}

function Message({
  activeMessageId,
  message,
  selected,
  setSelected,
}: {
  activeMessageId?: string
  message: MessagePreview
  selected: Set<string>
  setSelected: Dispatch<SetStateAction<Set<string>>>
}) {
  const { PreferredDateTimeComponent } = useDatesContext()
  const routes = useRoutesContext()

  const navigate = useNavigate()

  const messageRef = useRef<HTMLLIElement>(null)

  const isActiveMessage = activeMessageId && activeMessageId === message.messageId

  useEffect(() => {
    const element = messageRef.current

    if (isActiveMessage && element) {
      const rect = element.getBoundingClientRect()

      // Check if the element is in the viewport
      if (
        rect.top < 0 ||
        rect.bottom > (window.innerHeight || document.documentElement.clientHeight)
      ) {
        // Scroll the element into view smoothly
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function openMessage() {
    if (!routes.inboxMessage) {
      return
    }

    navigate({
      to: routes.inboxMessage({ messageId: message.messageId }),
    })
  }

  {
  }

  return (
    <ListItem
      ref={messageRef}
      data-from={message.from}
      data-messageId={message.messageId}
      data-preview={message.preview}
      data-read={message.read.toString()}
      data-receivedDate={message.receivedDate}
      data-subject={message.subject}
      id={`message-${message.messageId}`}
    >
      <HStack>
        <Checkbox
          isChecked={selected.has(message.messageId)}
          sx={{
            '.chakra-checkbox__input:not(:checked) ~ .chakra-checkbox__control': {
              bg: 'white',
            },
          }}
          onChange={(e) => {
            setSelected((oldValue) => {
              const newValue = new Set(oldValue)

              if (e.target.checked) {
                newValue.add(message.messageId)
              } else {
                newValue.delete(message.messageId)
              }

              return newValue
            })
          }}
        />

        <ComposedCard
          background={isActiveMessage ? 'blue.50' : undefined}
          borderLeft={!message.read ? '7px' : undefined}
          borderLeftColor="blue.300"
          borderLeftStyle="solid"
          cardBodyProps={{
            ml: !message.read ? '-7px' : undefined,
          }}
          cursor={!!routes.inboxMessage ? 'pointer' : undefined}
          size="sm"
          w="80"
          onClick={openMessage}
        >
          <HStack justifyContent="space-between">
            <Text fontWeight="bold">
              <From from={message.from} />
            </Text>

            <Text fontSize="xs" opacity={0.7}>
              <PreferredDateTimeComponent
                format={isToday(message.receivedDate) ? STANDARD_TIME_FORMAT : STANDARD_DATE_FORMAT}
                property="receivedDate"
              >
                {message.receivedDate}
              </PreferredDateTimeComponent>
            </Text>
          </HStack>

          <Text fontSize="sm">{message.subject}</Text>
          <Text fontSize="sm" opacity={0.7}>
            {message.preview}
          </Text>
        </ComposedCard>
      </HStack>
    </ListItem>
  )
}
