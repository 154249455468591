import z from 'zod'
import {
  AssignableType,
  AssignableTypeEnum,
  AssigneeTypeEnumSchema,
  ClaimAssigneeRelationshipLaxSchema,
  JobAssigneeRelationshipEnumSchema,
} from '../entities/assignment.js'
import { AssignmentsServiceEventBaseSchema, serviceEventType } from './service.js'

export const unassignedEventType = <V extends AssignableType>(entityType?: V) =>
  serviceEventType(entityType, 'unassigned')

export const EntityUnassignedEventMetadataSchema = z.discriminatedUnion('assignableType', [
  z.object({
    assignableType: z.literal(AssignableTypeEnum.claim),
    assigneeType: AssigneeTypeEnumSchema,
    assigneeRelationship: ClaimAssigneeRelationshipLaxSchema,
    requestActor: z.string(),
  }),
  z.object({
    assignableType: z.literal(AssignableTypeEnum.job),
    assigneeType: AssigneeTypeEnumSchema,
    assigneeRelationship: JobAssigneeRelationshipEnumSchema,
    requestActor: z.string(),
  }),
])

export const EntityUnassignedEventSchema = AssignmentsServiceEventBaseSchema.merge(
  z.object({
    type: unassignedEventType(),
    metadata: EntityUnassignedEventMetadataSchema,
    assignment: z.object({
      assignableId: z.string(),
      assigneeId: z.string(),
      requestActor: z.string(),
    }),
  })
)

export type EntityUnassignedEventMetadata = z.infer<typeof EntityUnassignedEventMetadataSchema>
export type EntityUnassignedEvent = z.infer<typeof EntityUnassignedEventSchema>
