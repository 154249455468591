import { EventBase } from '@eigtech/event-stream-types'
import { z } from 'zod'
import {
  ClaimStatusAdminEventName,
  ClaimStatusCanon,
  ClaimStatusCanonSchema,
  ClaimStatusEventNameCanon,
  ClaimStatusLax,
  ClaimStatusLaxSchema,
} from '../aggregates/claimStatus.js'
import { ClaimsServiceEventBaseSchema, claimsServiceEventType } from './service.js'

/** @deprecated */
type ClaimStatusEventType = ClaimStatusEventNameCanon | ClaimStatusAdminEventName
/** @deprecated */
const statusEventType = <V extends ClaimStatusEventType | undefined>(eventName?: V) =>
  claimsServiceEventType('status', eventName)
/** @deprecated */
export const ClaimStatusEventSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: statusEventType(),
    status: ClaimStatusCanonSchema.and(z.record(z.unknown())),
  })
)
/** @deprecated */
export const ClaimStatusEventLaxSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: statusEventType(),
    status: ClaimStatusLaxSchema.and(z.record(z.unknown())),
  })
)
/** @deprecated */
export const LossDateEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('lossDate'),
  })
)
/** @deprecated */
export const InitialCustomerContactAttemptedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('initialCustomerContactAttempted'),
  })
)
/** @deprecated */
export const InitialCustomerContactSuccessEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('initialCustomerContactSuccess'),
  })
)
/** @deprecated */
export const InitialCustomerContactFailedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('initialCustomerContactFailed'),
  })
)
/** @deprecated */
export const InvalidContactInformationEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('invalidContactInformation'),
  })
)
/** @deprecated */
export const CustomerNoContactEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('customerNoContact'),
  })
)
/** @deprecated */
export const FieldAdjusterAssignedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('fieldAdjusterAssigned'),
  })
)
/** @deprecated */
export const FieldAdjusterReassignedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('fieldAdjusterReassigned'),
  })
)
/** @deprecated */
export const FieldAdjusterRemovedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('fieldAdjusterRemoved'),
  })
)
/** @deprecated */
export const FieldAdjusterContactedCustomerEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('fieldAdjusterContactedCustomer'),
  })
)
/** @deprecated */
export const FieldAdjusterCorrectionUploadedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('fieldAdjusterCorrectionUploaded'),
  })
)
/** @deprecated */
export const FieldAdjusterInspectionScheduledEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('fieldAdjusterInspectionScheduled'),
    status: ClaimStatusEventSchema.shape.status.and(
      z.object({
        scheduledDate: z.string().optional(),
      })
    ),
  })
)
/** @deprecated */
export const FieldAdjusterInspectionCompletedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('fieldAdjusterInspectionCompleted'),
  })
)
/** @deprecated */
export const JobNotSoldEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('jobNotSold'),
  })
)
/** @deprecated */
export const FieldAdjusterEstimateUploadedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('fieldAdjusterEstimateUploaded'),
  })
)
/** @deprecated */
export const ClientApprovedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('clientApproved'),
  })
)
/** @deprecated */
export const ClientRejectedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('clientRejected'),
  })
)
/** @deprecated */
export const DateReceivedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('dateReceived'),
  })
)
/** @deprecated */
export const QaApprovedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('qaApproved'),
  })
)
/** @deprecated */
export const QaApprovedPreliminaryReportEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('qaApprovedPreliminaryReport'),
  })
)
/** @deprecated */
export const QaApprovedSupplementReportEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('qaApprovedSupplementReport'),
  })
)
/** @deprecated */
export const QARejectedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('qaRejected'),
  })
)
/** @deprecated */
export const EstimateSentToCarrierEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('estimateSentToCarrier'),
  })
)
/** @deprecated */
export const ClaimInvoiceCreatedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('claimInvoiceCreated'),
  })
)
/** @deprecated */
export const ReInspectionRequestedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('reInspectionRequested'),
  })
)
/** @deprecated */
export const EstimateRevisionRequestedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('estimateRevisionRequested'),
  })
)
/** @deprecated */
export const OTRInvokedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('otrInvoked'),
  })
)
/** @deprecated */
export const WADSentToCustomerEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('wadSentToCustomer'),
  })
)
/** @deprecated */
export const CarrierApprovedEstimateEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('carrierApprovedEstimate'),
  })
)
/** @deprecated */
export const CarrierApprovedEstimateWithExceptionsEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('carrierApprovedEstimateWithExceptions'),
  })
)
/** @deprecated */
export const CarrierRejectedEstimateEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('carrierRejectedEstimate'),
  })
)
/** @deprecated */
export const JobContractedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('jobContracted'),
  })
)
/** @deprecated */
export const JobCompletedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('jobCompleted'),
  })
)
/** @deprecated */
export const CanceledEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('canceled'),
  })
)
/** @deprecated */
export const CoordinatorAssignedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('coordinatorAssigned'),
  })
)
/** @deprecated */
export const CoordinatorRemovedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('coordinatorRemoved'),
  })
)
/** @deprecated */
export const InAssignQueueEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('inAssignQueue'),
  })
)
/** @deprecated */
export const SurveySentToCustomerEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('surveySentToCustomer'),
  })
)

/** @deprecated */
export const CustomerContactedEmailEventSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: statusEventType('customerContactedEmail'),
    status: ClaimStatusCanonSchema,
  })
)
/** @deprecated */
export const CustomerContactedLvmEventSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: statusEventType('customerContactedLvm'),
    status: ClaimStatusCanonSchema,
  })
)
/** @deprecated */
export const JobSoldEventSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: statusEventType('jobSold'),
    status: ClaimStatusCanonSchema,
  })
)
/** @deprecated */
export const JobStartedEventSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: statusEventType('jobStarted'),
    status: ClaimStatusCanonSchema,
  })
)

/** @deprecated */
export const StatusIdUpdatedEventSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: statusEventType('idUpdated'),
    status: z.object({
      previous: ClaimStatusCanonSchema,
      current: ClaimStatusCanonSchema,
    }),
  })
)

/** @deprecated */
export const StatusDeletedEventSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: statusEventType('deleted'),
    status: ClaimStatusCanonSchema,
  })
)

/** @deprecated */
export const ProjectManagerAssignedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('projectManagerAssigned'),
  })
)
/** @deprecated */
export const ProjectManagerRemovedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('projectManagerRemoved'),
  })
)

/** @deprecated */
export const ReviewerAssignedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('reviewerAssigned'),
  })
)
/** @deprecated */
export const ReviewerRemovedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('reviewerRemoved'),
  })
)
////////////////
// TypeGuards //
////////////////
/** @deprecated */
export const isFieldAdjusterInspectionScheduledEvent = (
  event: EventBase
): event is FieldAdjusterInspectionScheduledEvent =>
  FieldAdjusterInspectionScheduledEventSchema.safeParse(event).success
/** @deprecated */
export const isQaApprovedEvent = (event: EventBase): event is QaApprovedEvent =>
  QaApprovedEventSchema.safeParse(event).success
/** @deprecated */
export const isQaRejectedEvent = (event: EventBase): event is QARejectedEvent =>
  QARejectedEventSchema.safeParse(event).success

/** @deprecated */
export const isCustomerContactedEmailEvent = (
  event: EventBase
): event is CustomerContactedEmailEvent =>
  CustomerContactedEmailEventSchema.safeParse(event).success
/** @deprecated */
export const isCustomerContactedLvmEvent = (event: EventBase): event is CustomerContactedLvmEvent =>
  CustomerContactedLvmEventSchema.safeParse(event).success
/** @deprecated */
export const isJobSoldEvent = (event: EventBase): event is JobSoldEvent =>
  JobSoldEventSchema.safeParse(event).success
/** @deprecated */
export const isJobStartedEvent = (event: EventBase): event is JobStartedEvent =>
  JobStartedEventSchema.safeParse(event).success

///////////////////
// TYPES EXPORTS //
///////////////////
/** @deprecated */
export type ClaimStatusEventCanon = z.infer<typeof ClaimStatusEventSchema> & {
  status: ClaimStatusCanon
}
/** @deprecated */
export type ClaimStatusEventLax = z.infer<typeof ClaimStatusEventLaxSchema> & {
  status: ClaimStatusLax
}

/** @deprecated */
export type LossDateEvent = z.infer<typeof LossDateEventSchema>
/** @deprecated */
export type InitialCustomerContactAttemptedEvent = z.infer<
  typeof InitialCustomerContactAttemptedEventSchema
>
/** @deprecated */
export type InitialCustomerContactSuccessEvent = z.infer<
  typeof InitialCustomerContactSuccessEventSchema
>
/** @deprecated */
export type InitialCustomerContactFailedEvent = z.infer<
  typeof InitialCustomerContactFailedEventSchema
>
/** @deprecated */
export type InvalidContactInformationEvent = z.infer<typeof InvalidContactInformationEventSchema>
/** @deprecated */
export type CustomerNoContactEvent = z.infer<typeof CustomerNoContactEventSchema>
/** @deprecated */
export type FieldAdjusterAssignedEvent = z.infer<typeof FieldAdjusterAssignedEventSchema>
/** @deprecated */
export type FieldAdjusterReassignedEvent = z.infer<typeof FieldAdjusterReassignedEventSchema>
/** @deprecated */
export type FieldAdjusterRemovedEvent = z.infer<typeof FieldAdjusterRemovedEventSchema>
/** @deprecated */
export type FieldAdjusterContactedCustomerEvent = z.infer<
  typeof FieldAdjusterContactedCustomerEventSchema
>
/** @deprecated */
export type FieldAdjusterCorrectionUploadedEvent = z.infer<
  typeof FieldAdjusterCorrectionUploadedEventSchema
>
/** @deprecated */
export type FieldAdjusterInspectionScheduledEvent = z.infer<
  typeof FieldAdjusterInspectionScheduledEventSchema
>
/** @deprecated */
export type FieldAdjusterInspectionCompletedEvent = z.infer<
  typeof FieldAdjusterInspectionCompletedEventSchema
>
/** @deprecated */
export type JobNotSoldEvent = z.infer<typeof JobNotSoldEventSchema>
/** @deprecated */
export type FieldAdjusterEstimateUploadedEvent = z.infer<
  typeof FieldAdjusterEstimateUploadedEventSchema
>
/** @deprecated */
export type ClientApprovedEvent = z.infer<typeof ClientApprovedEventSchema>
/** @deprecated */
export type ClientRejectedEvent = z.infer<typeof ClientRejectedEventSchema>
/** @deprecated */
export type DateReceivedEvent = z.infer<typeof DateReceivedEventSchema>
/** @deprecated */
export type QaApprovedEvent = z.infer<typeof QaApprovedEventSchema>
/** @deprecated */
export type QARejectedEvent = z.infer<typeof QARejectedEventSchema>
/** @deprecated */
export type EstimateSentToCarrierEvent = z.infer<typeof EstimateSentToCarrierEventSchema>
/** @deprecated */
export type ClaimInvoiceCreatedEvent = z.infer<typeof ClaimInvoiceCreatedEventSchema>
/** @deprecated */
export type ReInspectionRequestedEvent = z.infer<typeof ReInspectionRequestedEventSchema>
/** @deprecated */
export type EstimateRevisionRequestedEvent = z.infer<typeof EstimateRevisionRequestedEventSchema>
/** @deprecated */
export type OTRInvokedEvent = z.infer<typeof OTRInvokedEventSchema>
/** @deprecated */
export type WADSentToCustomerEvent = z.infer<typeof WADSentToCustomerEventSchema>
/** @deprecated */
export type CarrierApprovedEstimateEvent = z.infer<typeof CarrierApprovedEstimateEventSchema>
/** @deprecated */
export type CarrierApprovedEstimateWithExceptionsEvent = z.infer<
  typeof CarrierApprovedEstimateWithExceptionsEventSchema
>
/** @deprecated */
export type CarrierRejectedEstimateEvent = z.infer<typeof CarrierRejectedEstimateEventSchema>
/** @deprecated */
export type JobContractedEvent = z.infer<typeof JobContractedEventSchema>
/** @deprecated */
export type JobCompletedEvent = z.infer<typeof JobCompletedEventSchema>
/** @deprecated */
export type CanceledEvent = z.infer<typeof CanceledEventSchema>
/** @deprecated */
export type CoordinatorAssignedEvent = z.infer<typeof CoordinatorAssignedEventSchema>
/** @deprecated */
export type CoordinatorRemovedEvent = z.infer<typeof CoordinatorRemovedEventSchema>
/** @deprecated */
export type InAssignQueueEvent = z.infer<typeof InAssignQueueEventSchema>
/** @deprecated */
export type SurveySentToCustomerEvent = z.infer<typeof SurveySentToCustomerEventSchema>
/** @deprecated */
export type ReviewerAssignedEvent = z.infer<typeof ReviewerAssignedEventSchema>

/** @deprecated */
export type StatusIdUpdatedEvent = z.infer<typeof StatusIdUpdatedEventSchema>
/** @deprecated */
export type StatusDeletedEvent = z.infer<typeof StatusDeletedEventSchema>

/** @deprecated */
export type CustomerContactedEmailEvent = z.infer<typeof CustomerContactedEmailEventSchema>
/** @deprecated */
export type CustomerContactedLvmEvent = z.infer<typeof CustomerContactedLvmEventSchema>
/** @deprecated */
export type JobSoldEvent = z.infer<typeof JobSoldEventSchema>
/** @deprecated */
export type JobStartedEvent = z.infer<typeof JobStartedEventSchema>
