import { ContactCornSchema } from '@eigtech/contacts-types'
import { z } from 'zod'
import { ClaimNumberSchema, EstimateIdSchema } from './base.js'
import { PublicationTargetsSchema } from './publishable.js'

export const EstimateReviewerRoleSchema = z.enum(['qa', 'carrier', 'client', 'other'])

export const EstimateReviewOutcomeSchema = z.enum(['approved', 'rejected', 'withExceptions'])

export const EstimateReviewerRoleEnum = EstimateReviewerRoleSchema.Enum
export const EstimateReviewOutcomeEnum = EstimateReviewOutcomeSchema.Enum

export const EstimateReviewSchema = z.object({
  claimNumber: ClaimNumberSchema,
  estimateId: EstimateIdSchema,
  reviewerContact: ContactCornSchema.optional(),
  reviewerRole: EstimateReviewerRoleSchema,
  outcome: EstimateReviewOutcomeSchema,
  additionalInformation: z.string().optional(),
  reviewedOn: z.string().datetime(),
  publicationTargets: z.array(PublicationTargetsSchema).optional(),
})

export type EstimateReview = z.infer<typeof EstimateReviewSchema>
export type EstimateReviewerRole = z.infer<typeof EstimateReviewerRoleSchema>
export type EstimateReviewOutcome = z.infer<typeof EstimateReviewOutcomeSchema>
