import { ensureClaimV2Corn, makeClaimV2Corn, isClaimV2Corn } from '@eigtech/claims-v2-util'
import { serviceName } from '@eigtech/claims-v2-types'
import { Corn, getCornKernels } from '@eigtech/shared-corn'
import { CurrentClaimCorn } from '../../types/index.js'

export const currentClaimCornService = serviceName
export const makeClaimCorn: (resourceId: string) => CurrentClaimCorn = makeClaimV2Corn
export const ensureClaimCorn: (corn: string | Corn) => CurrentClaimCorn = ensureClaimV2Corn
export const isClaimCorn: (corn: string | CurrentClaimCorn) => boolean = isClaimV2Corn
export const ensureClaimNumber = (entityId: string | Corn): string =>
  getCornKernels(ensureClaimCorn(entityId)).resourceId
