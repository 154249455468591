import { EventBase } from '@eigtech/event-stream-types'
import { z } from 'zod'
import { LossDetailsSchema } from '../aggregates/lossDetails.js'
import { ClaimsServiceEventBaseSchema, claimsServiceEventType } from './service.js'

/** @deprecated */
export const lossDetailsEntityName = 'lossDetails'

const lossDetailsEventType = <V extends string | undefined>(eventName?: V) =>
  claimsServiceEventType(lossDetailsEntityName, eventName)

/** @deprecated */
export const LossDetailsEventSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: lossDetailsEventType(),
    lossDetails: z.unknown(),
  })
)

/** @deprecated */
export const LossDetailsCreatedEventSchema = LossDetailsEventSchema.merge(
  z.object({
    type: lossDetailsEventType('created'),
    lossDetails: LossDetailsSchema,
  })
)

/** @deprecated */
export const LossDetailsUpdatedEventSchema = LossDetailsEventSchema.merge(
  z.object({
    type: lossDetailsEventType('updated'),
    lossDetails: LossDetailsSchema.partial().merge(
      LossDetailsSchema.pick({ id: true, claimNumber: true })
    ),
  })
)

/** @deprecated */
export const isLossDetailsCreatedEvent = (event: EventBase): event is LossDetailsCreatedEvent =>
  LossDetailsCreatedEventSchema.safeParse(event).success
/** @deprecated */
export const isLossDetailsUpdatedEvent = (event: EventBase): event is LossDetailsUpdatedEvent =>
  LossDetailsUpdatedEventSchema.safeParse(event).success

/** @deprecated */
export type LossDetailsEvent = z.infer<typeof LossDetailsEventSchema>
/** @deprecated */
export type LossDetailsCreatedEvent = z.infer<typeof LossDetailsCreatedEventSchema>
/** @deprecated */
export type LossDetailsUpdatedEvent = z.infer<typeof LossDetailsUpdatedEventSchema>
