import {
  chakra,
  HTMLChakraProps,
  SystemProps,
  forwardRef,
  useBreakpointValue,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { usePropertyListContext } from './PropertyList'

type PropertyVariant = 'horizontal' | 'vertical'

export type PropertyProps = HTMLChakraProps<'dl'> & {
  label?: ReactNode
  value?: ReactNode
  labelWidth?: SystemProps['width']
  spacing?: SystemProps['margin']
  labelProps?: PropertyLabelProps
  valueProps?: PropertyValueProps
  variant?:
    | PropertyVariant
    | Partial<Record<string, PropertyVariant>>
    | Array<PropertyVariant | null>
}

export const Property = forwardRef<PropertyProps, 'dl'>(function Property(
  {
    label,
    labelWidth: labelWidthOverride,
    value,
    spacing: spacingOverride,
    children,
    labelProps,
    valueProps,
    variant: propsVariant = 'horizontal',
    ...props
  },
  ref
) {
  const variantOverride = useBreakpointValue(
    typeof propsVariant === 'string' ? [propsVariant] : propsVariant
  )

  const {
    labelWidth,
    spacing,
    hasPropertyListParent,
    variant = variantOverride,
  } = usePropertyListContext()

  return (
    <chakra.dl
      ref={ref}
      as={hasPropertyListParent ? 'div' : 'dl'}
      display="flex"
      flexDirection={variant === 'horizontal' ? 'row' : 'column'}
      {...props}
    >
      {label && (
        <PropertyLabel
          marginBottom={variant === 'vertical' ? (spacingOverride ?? spacing) : undefined}
          marginRight={variant === 'horizontal' ? (spacingOverride ?? spacing) : undefined}
          noOfLines={props.noOfLines}
          width={labelWidthOverride ?? labelWidth}
          {...labelProps}
        >
          {label}
        </PropertyLabel>
      )}
      {value && <PropertyValue {...valueProps}>{value}</PropertyValue>}
      {children}
    </chakra.dl>
  )
})

export type PropertyLabelProps = HTMLChakraProps<'dt'>

export const PropertyLabel = forwardRef<PropertyLabelProps, 'dt'>(function PropertyLabel(
  { children, noOfLines = 1, width, minWidth, ...props },
  ref
) {
  const labelStyles: PropertyLabelProps = {
    color: 'gray.600',
    display: 'flex',
    flexDirection: 'row',
    minWidth: minWidth ?? '48',
    width: '64',
    marginRight: '2',
  }

  if (width) {
    labelStyles.minWidth = minWidth || 'auto'
    labelStyles.width = width
  }

  return (
    <chakra.dt ref={ref} {...labelStyles} {...props}>
      <chakra.span flex="1" noOfLines={noOfLines}>
        {children}
      </chakra.span>
    </chakra.dt>
  )
})

export type PropertyValueProps = HTMLChakraProps<'dd'>

export const PropertyValue = forwardRef<PropertyValueProps, 'dd'>(function PropertyValue(
  { children, ...props },
  ref
) {
  const valueStyles: PropertyValueProps = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  }

  return (
    <chakra.dd ref={ref} {...valueStyles} {...props}>
      {children}
    </chakra.dd>
  )
})
