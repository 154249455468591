import { Contact, ContactCommunicationInformation } from '@eigtech/contacts-types'
import { getContactPrimaryPhone } from './getPhone.js'
import { getContactPrimaryEmail } from './getEmail.js'

/**
 * Returns the `communicationInformation` of a contact based on preferrence.
 *
 * Preferrence is determined as follows:
 * - preferredMethodOfContact
 * - preferenceOrder
 * - email, phone
 */
export const getPreferredCommunicationInformation = ({
  communicationInformation,
  preferredMethodOfContact,
}: Pick<Contact, 'communicationInformation' | 'preferredMethodOfContact'>):
  | ContactCommunicationInformation
  | undefined => {
  const preferredOptions = preferredMethodOfContact
    ? communicationInformation.filter(
        (info) => info.telecommunicationUseCode === preferredMethodOfContact
      )
    : undefined

  const selectedItems = preferredOptions?.length ? preferredOptions : communicationInformation

  const phone = getContactPrimaryPhone({ communicationInformation: selectedItems })
  const email = getContactPrimaryEmail({ communicationInformation: selectedItems })

  if (!!phone && !!email) {
    return phone.preferenceOrder === email.preferenceOrder
      ? email || phone
      : getCommunicationInformationByPreferenceOrder([phone, email])
  }

  return email || phone
}

const preferrenceOrderComparator = (
  a: ContactCommunicationInformation,
  b: ContactCommunicationInformation
) => ((a.preferenceOrder ?? 99) < (b.preferenceOrder ?? 99) ? -1 : 1)

const getCommunicationInformationByPreferenceOrder = (
  communicationInformation: ContactCommunicationInformation[]
) => {
  communicationInformation.sort(preferrenceOrderComparator)
  return communicationInformation[0]
}
