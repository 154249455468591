import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { ContactCornSchema, ContactSchema } from '../../../base/contacts.js'
import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types'

export const CreateContactApiSchema = {
  body: ContactSchema.omit({ contactId: true }),
  headers: ApiLambdalerAuthHeaderWithRequestActorSchema.pick({ requestActor: true }),
  response: z.object({ contactId: ContactCornSchema }),
} satisfies ApiSchema

export const CreateContactRequestBodySchema = CreateContactApiSchema.body
export const CreateContactRequestSchema = CreateContactApiSchema.body.merge(
  CreateContactApiSchema.headers
)
export const CreateContactResponseSchema = CreateContactApiSchema.response

export type CreateContactRequestBody = z.infer<typeof CreateContactApiSchema.body>
export type CreateContactRequest = z.infer<typeof CreateContactRequestSchema>

export type CreateContactResponse = z.infer<typeof CreateContactResponseSchema>

export interface CreateContactHandler
  extends Handler<CreateContactRequest, CreateContactResponse> {}
