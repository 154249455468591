import {
  ContactRoleConsumerIn,
  ContactRolesConsumerSchema,
  GlobalBookCornCanon,
  GlobalBookCornCanonSchema,
  GlobalBookCornLax,
  GlobalBookQueryModelCanon,
  GlobalBookQueryModelCanonSchema,
  GlobalBookQueryModelLax,
  GlobalBookQueryModelLaxSchema,
  GlobalBookResourceIdCanonSchema,
  GlobalBookResourceIdLaxSchema,
} from '@eigtech/contacts-types'
import { getCornKernels, getResourceId } from '@eigtech/shared-corn'
import { makeGlobalBookCorn } from '../corn/index.js'

GlobalBookQueryModelLaxSchema
GlobalBookQueryModelCanonSchema

export const laxToCanonGlobalBookCorn = (corn: GlobalBookCornLax): GlobalBookCornCanon =>
  `corn:contacts:book:${GlobalBookResourceIdCanonSchema.parse(getCornKernels(corn).resourceId)}`

export const laxToCanonGlobalBookQueryModel = (
  queryModel: GlobalBookQueryModelLax
): GlobalBookQueryModelCanon => ({
  ...queryModel,
  bookId: laxToCanonGlobalBookCorn(queryModel.bookId),
  contacts: Object.fromEntries(
    Object.entries(queryModel.contacts).map(([contactId, contact]) => {
      const { roles: rolesIn } = contact
      const roleArrayOrSet = ContactRolesConsumerSchema.parse(rolesIn)
      return [
        contactId,
        {
          ...contact,
          roles: Array.from(roleArrayOrSet),
        },
      ]
    })
  ),
})

export const isGlobalBookId = (bookId: string): bookId is GlobalBookCornCanon => {
  const parseResult = GlobalBookCornCanonSchema.safeParse(bookId)
  return (
    parseResult.success &&
    GlobalBookResourceIdLaxSchema.safeParse(getResourceId(parseResult.data)).success
  )
}

const globalBookIdFromRoleMap: Record<ContactRoleConsumerIn, GlobalBookCornCanon | undefined> = {
  agent: undefined,
  approver: makeGlobalBookCorn('projectManager'),
  carrier: makeGlobalBookCorn('carrier'),
  coordinator: makeGlobalBookCorn('csr'),
  csr: makeGlobalBookCorn('csr'),
  deskAdjuster: makeGlobalBookCorn('deskAdjuster'),
  dispatcher: undefined,
  fieldAdjuster: makeGlobalBookCorn('fieldAdjuster'),
  fieldTechnician: makeGlobalBookCorn('fieldTechnician'),
  fileReviewer: makeGlobalBookCorn('reviewer'),
  inspectionTechnician: makeGlobalBookCorn('fieldAdjuster'),
  legalRepresentation: undefined,
  mortgageHolder: undefined,
  other: undefined,
  otherClaimContact: undefined,
  otherContact: undefined,
  policyHolder: undefined,
  primaryClaimContact: undefined,
  primaryContact: undefined,
  projectManager: makeGlobalBookCorn('projectManager'),
  propertyAccessContact: undefined,
  qa: makeGlobalBookCorn('reviewer'),
  reviewer: makeGlobalBookCorn('reviewer'),
  subcontractor: makeGlobalBookCorn('subcontractor'),
  thirdPartyClaimant: undefined,
}

export const getGlobalBookIdFromRole = (
  role: ContactRoleConsumerIn
): GlobalBookCornCanon | undefined => globalBookIdFromRoleMap[role]
