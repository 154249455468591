import { DocumentCornSchema } from '@eigtech/documents-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema } from '../model/index.js'
import { BaseHeaderSchema } from './base.js'

const RemoveLossOfUseReceiptSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
    documentCorn: DocumentCornSchema,
    id: z.string(),
  }),
  headers: BaseHeaderSchema,
}

export const RemoveLossOfUseReceiptRequestSchema = RemoveLossOfUseReceiptSchemas.params.merge(
  RemoveLossOfUseReceiptSchemas.headers
)

const RemoveLossOfUseReceiptInputSchema = RemoveLossOfUseReceiptRequestSchema.merge(
  z.object({
    documentCorn: DocumentCornSchema,
    receiptDateRemoved: z.string().datetime(),
  })
)

export type RemoveLossOfUseReceiptInput = z.infer<typeof RemoveLossOfUseReceiptInputSchema>

export type RemoveLossOfUseReceiptHeaderRequest = z.infer<
  typeof RemoveLossOfUseReceiptSchemas.headers
>
export type RemoveLossOfUseReceiptRequest = z.infer<typeof RemoveLossOfUseReceiptRequestSchema>
export type RemoveLossOfUseReceiptResponse = void

export interface RemoveLossOfUseReceiptHandler
  extends Handler<RemoveLossOfUseReceiptRequest, RemoveLossOfUseReceiptResponse> {}

export const RemoveLossOfUseReceiptApiSchema: ApiSchema = RemoveLossOfUseReceiptSchemas
