import { InboxMessage } from '@eigtech/inbox-types'
import { JobId } from '@eigtech/restoration-types'
import { STANDARD_DATE_TIME_FORMAT, useDatesContext } from '@eigtech/ui-shared-dates'
import {
  Button,
  ButtonGroup,
  ComposedAlert,
  ComposedCard,
  HStack,
  Icon,
  IconButton,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
  Tooltip,
  useToast,
} from '@eigtech/ui-shared-dave'
import { LinkButton, useNavigate, useRoutesContext } from '@eigtech/ui-shared-router'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer'
import { exhaustive } from 'exhaustive'
import { useEffect } from 'react'
import { MdClose, MdError } from 'react-icons/md'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {
  useArchiveMessage,
  useGetMessage,
  useMarkMessageAsRead,
  useMarkMessageAsUnread,
} from '../api'
import { From } from './Shared'

export type MessageDetailProps = { messageId: string }

export function MessageDetail({ messageId }: MessageDetailProps) {
  const { PreferredDateTimeComponent } = useDatesContext()
  const routes = useRoutesContext()

  const navigate = useNavigate()

  const { data: message, isPending, isError } = useGetMessage({ messageId })

  const toast = useToast()

  const {
    mutateAsync: archiveMessage,
    isPending: isArchiving,
    isError: isArchiveError,
  } = useArchiveMessage()
  const {
    mutateAsync: markMessageAsRead,
    isPending: isMarkingAsRead,
    isError: isMarkAsReadError,
  } = useMarkMessageAsRead()
  const {
    mutateAsync: markMessageAsUnread,
    isPending: isMarkingAsUnread,
    isError: isMarkAsUnreadError,
  } = useMarkMessageAsUnread()

  useEffect(() => {
    markMessageAsRead({ messageId })
  }, [markMessageAsRead, messageId])

  async function handleArchiveMessage() {
    await archiveMessage({ messageId })
    handleClose()
    toast({
      status: 'success',
      title: 'Successfully archived message',
    })
  }

  async function handleToggleRead() {
    if (message?.read) {
      await markMessageAsUnread({ messageId })
      toast({
        status: 'success',
        title: 'Successfully marked message as unread',
      })
    } else {
      await markMessageAsRead({ messageId })
      toast({
        status: 'success',
        title: 'Successfully marked message as read',
      })
    }
  }

  function handleClose() {
    navigate({ to: routes.inbox })
  }

  if (!isPending && !isError && !message) {
    return <ComposedAlert alert="No message found" status="error" />
  }

  return (
    <ComposedCard
      flexGrow={1}
      heading={
        <HStack justifyContent="space-between">
          <Stack spacing="1">
            <Skeleton isLoaded={!isPending}>
              <Text fontWeight="bold">
                <From from={isError ? '(Unknown)' : (message?.from ?? 'Loading sender...')} />
              </Text>
            </Skeleton>

            <Skeleton isLoaded={!isPending}>
              <Text>
                {isError ? '(Unknown)' : (message?.subject ?? 'Loading message subject...')}
              </Text>
            </Skeleton>
          </Stack>

          <Stack alignItems="flex-end">
            <Skeleton isLoaded={!isPending}>
              <Text fontSize="xs" opacity={0.7}>
                <PreferredDateTimeComponent
                  format={STANDARD_DATE_TIME_FORMAT}
                  property="receivedDate"
                >
                  {message?.receivedDate}
                </PreferredDateTimeComponent>
              </Text>
            </Skeleton>

            <ButtonGroup isDisabled={!message} size="xs" spacing="1" variant="outline">
              <Tooltip isDisabled={!isArchiveError} label="Could not archive message">
                <Button
                  colorScheme={isArchiveError ? 'red' : undefined}
                  isLoading={isArchiving}
                  leftIcon={isArchiveError ? <MdError /> : undefined}
                  onClick={handleArchiveMessage}
                >
                  Archive
                </Button>
              </Tooltip>

              <Tooltip
                isDisabled={!(isMarkAsReadError || isMarkAsUnreadError)}
                label={`Could not mark message as ${isMarkAsReadError ? 'read' : 'unread'} `}
              >
                <Button
                  colorScheme={isMarkAsReadError || isMarkAsUnreadError ? 'red' : undefined}
                  isLoading={isMarkingAsRead || isMarkingAsUnread}
                  leftIcon={isMarkAsReadError || isMarkAsUnreadError ? <MdError /> : undefined}
                  onClick={handleToggleRead}
                >
                  Mark {message?.read ? 'Unread' : 'Read'}
                </Button>
              </Tooltip>

              <IconButton
                aria-label="Close message"
                icon={<Icon as={MdClose} />}
                isDisabled={false}
                variant="ghost"
                onClick={handleClose}
              />
            </ButtonGroup>

            <Context context={message?.context ?? {}} />
          </Stack>
        </HStack>
      }
      maxH="calc(100vh - 14rem)"
    >
      {!!message ? (
        <Body message={message} />
      ) : isError ? (
        <ComposedAlert alert="Could not load selected message" status="error" />
      ) : (
        <SkeletonText noOfLines={8} skeletonHeight="6" spacing="2" />
      )}
    </ComposedCard>
  )
}

function Body({ message }: { message: InboxMessage }) {
  return exhaustive.tag(message, 'bodyFormat', {
    markdown: () => (
      <Markdown components={ChakraUIRenderer()} remarkPlugins={[remarkGfm]} skipHtml>
        {message.body}
      </Markdown>
    ),
    text: () => <Text whiteSpace="preserve">{message.body}</Text>,
  })
}

function Context({ context }: Pick<InboxMessage, 'context'>) {
  const routes = useRoutesContext()

  if (!Object.values(context).length) return null

  return (
    <Stack spacing="2" w="full">
      <ButtonGroup justifyContent="flex-end" size="xs" variant="outline">
        {!!(context.claimNumber && routes.claimDetail) && (
          <LinkButton to={routes.claimDetail(context.claimNumber)}>View Claim</LinkButton>
        )}

        {!!(context.jobId && routes.jobDetail) && (
          <LinkButton to={routes.jobDetail(context.jobId as JobId)}>View Job</LinkButton>
        )}
      </ButtonGroup>
    </Stack>
  )
}
