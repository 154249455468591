import { z } from 'zod'
import { AobDetailsSchema } from './aob.js'
import {
  BaseClaimSchema,
  BaseEstimateSchema,
  ClaimNumberSchema,
  EstimateIdSchema,
  ExternalSystemIdSchema,
} from './base.js'
import { CustomerCommunicationSchema } from './customerCommunication.js'
import { EstimateReviewSchema } from './estimate.js'
import { InitialClaimActionsSchema } from './initialActions.js'
import { InspectionDetailsSchema, InspectorRoleSchema } from './inspections.js'
import { LossOfUseSchema } from './lossOfUse.js'
import { OutcomeSchema } from './outcome.js'
import { PublicationTargetsSchema } from './publishable.js'
import { ClaimReviewDetailsSchema } from './reviews.js'

const ClaimAggregateExternalSystemIdDetails = z.record(
  ExternalSystemIdSchema,
  z.object({
    value: z.string().optional(),
    operation: z.enum(['added', 'updated', 'removed']),
  })
)
/**
 * Updating an external system ID requires an upsert command. However, it may be important for
 * downstream processes to know if a value was "added" vs. "updated". These details are stored
 * in the aggregate to track the operation.
 */
export type ClaimAggregateExternalSystemIdDetails = z.infer<
  typeof ClaimAggregateExternalSystemIdDetails
>

export const ClaimAggregateTargetSchema = BaseClaimSchema.merge(
  z.object({
    aobDetails: AobDetailsSchema.optional(),
    customerCommunication: CustomerCommunicationSchema.array().optional(),
    externalSystemIdDetails: ClaimAggregateExternalSystemIdDetails.optional(),
    initialClaimActions: InitialClaimActionsSchema.array().optional(),
    inspections: z.record(InspectionDetailsSchema).optional(),
    latestInspection: z
      .record(InspectorRoleSchema, InspectionDetailsSchema.required({ inspectorRole: true }))
      .optional(),
    lossOfUse: z.record(LossOfUseSchema).optional(),
    outcome: OutcomeSchema.optional(),
    publicationTargets: PublicationTargetsSchema.array().optional(),
    receivedInAssignQueueEvent: z.boolean().optional(),
    reviews: ClaimReviewDetailsSchema.optional(),
  })
)

export type ClaimAggregateTarget = z.infer<typeof ClaimAggregateTargetSchema>

export const EstimateAggregateTargetSchema = BaseEstimateSchema

export const EstimateReviewsAggregateSchema = z.object({
  claimNumber: ClaimNumberSchema,
  latestEstimate: EstimateIdSchema,
  estimates: z.record(
    EstimateIdSchema,
    z.object({ estimate: BaseEstimateSchema, reviews: z.array(EstimateReviewSchema) })
  ),
})

export type EstimateAggregateTarget = z.infer<typeof EstimateAggregateTargetSchema>
export type EstimateReviewsAggregate = z.infer<typeof EstimateReviewsAggregateSchema>
