import { Contact, ContactName } from '@eigtech/contacts-types'
import { isInEffect } from '../utils/inEffect.js'

export const getContactName = (contact: Partial<Contact>): string | undefined => {
  const potentialNames = contact.name?.filter(isInEffect)
  if (!potentialNames?.length) {
    return undefined
  }

  const contactName = potentialNames[0]

  const surname =
    contactName.familyName?.surname ?? ''
      ? contactName.familyName?.surname ?? ''
      : `${contactName.familyName?.ownSurnamePrefix ?? '' + ' '}${
          contactName.familyName?.ownSurname ?? '' + ' '
        }${contactName.familyName?.surnamePrefixFromPartner ?? '' + ' '}${
          contactName.familyName?.surnameFromPartner ?? ''
        }`

  const nameBuilder = {
    prefix: contactName.prefix ? contactName.prefix + ' ' : '',
    middleName: contactName.middleName ? contactName.middleName + ' ' : '',
    suffix: contactName.suffix ? ' ' + contactName.suffix : '',
    givenName: contactName.givenName,
    surname,
  }

  const buildName = (
    nameBuilder: { prefix: string; middleName: string; givenName: string; suffix: string },
    order?: ContactName['nameAssemblyOrder']
  ) => {
    if (order && order === 'Prefix Family Middle Given Suffix') {
      return `${nameBuilder.prefix}${surname + ' '}${nameBuilder.middleName}${
        nameBuilder.givenName
      }${nameBuilder.suffix}`
    } else {
      return `${nameBuilder.prefix}${nameBuilder.givenName + ' '}${
        nameBuilder.middleName
      }${surname}${nameBuilder.suffix}`
    }
  }

  return buildName(nameBuilder, contactName.nameAssemblyOrder).trim() || contactName.rawName
}
