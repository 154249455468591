import { Book, Contact } from '@eigtech/contacts-types'
import { ComposedAlert, forwardRef, Skeleton, Stack, StackProps } from '@eigtech/ui-shared-dave'
import { range } from 'lodash-es'
import { ContactCard } from './ContactCard'

export type ContactsListProps = {
  book: Book | undefined
  contacts: Contact[]
  isPending?: boolean
  isError?: boolean
} & StackProps

export const ContactsList = forwardRef<ContactsListProps, 'div'>(
  ({ book, contacts, isError, isPending, ...props }, ref) => {
    return (
      <Stack ref={ref} spacing="4" {...props}>
        {isError ? (
          <ComposedAlert alert="Something went wrong retrieving these contacts." status="error" />
        ) : isPending ? (
          range(0, 3).map((i) => <Skeleton key={i} h="40" w="full" />)
        ) : !book || !contacts.length ? (
          <ComposedAlert alert="No contacts found." />
        ) : book?.metadata.type !== 'claim' ? (
          <ComposedAlert alert="Wrong book type." status="warning" />
        ) : (
          contacts.map((contact) => (
            <ContactCard key={contact.contactId} book={book} contact={contact} />
          ))
        )}
      </Stack>
    )
  }
)
