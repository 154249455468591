import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema, CompletedReviewSchema } from '../model/index.js'
import { BaseHeaderSchema } from './base.js'

const CompleteClaimReviewSchemas = {
  body: z.object({
    notes: z.string(),
  }),
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  headers: BaseHeaderSchema,
  response: z
    .object({
      claimNumber: ClaimNumberSchema,
    })
    .merge(CompletedReviewSchema),
}

export const CompleteClaimReviewRequestSchema = CompleteClaimReviewSchemas.body
  .merge(CompleteClaimReviewSchemas.params)
  .merge(CompleteClaimReviewSchemas.headers)

export type CompleteClaimReviewHeaderRequest = z.infer<typeof CompleteClaimReviewSchemas.headers>
export type CompleteClaimReviewRequest = z.infer<typeof CompleteClaimReviewRequestSchema>
export type CompleteClaimReviewResponse = z.infer<typeof CompleteClaimReviewSchemas.response>

export interface CompleteClaimReviewHandler
  extends Handler<CompleteClaimReviewRequest, CompleteClaimReviewResponse> {}

export const CompleteClaimReviewApiSchema: ApiSchema = CompleteClaimReviewSchemas
