import { z } from 'zod'
import { BaseClaimSchema } from '../model/base.js'
import {
  LossOfUseReceiptRemovedSchema,
  LossOfUseReceiptSchema,
  LossOfUseSchema,
} from '../model/lossOfUse.js'
import { ClaimEventSchema, ClaimEventType, claimEventType } from './claim.js'

export const LossOfUseEventSchema = ClaimEventSchema.merge(
  z.object({
    claim: BaseClaimSchema.merge(
      z.object({
        lossOfUse: LossOfUseSchema.omit({ receipts: true }),
      })
    ),
  })
)
export type LossOfUseEvent = z.infer<typeof LossOfUseEventSchema>

export const LossOfUseAddedEventSchema = LossOfUseEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.lossOfUseAdded),
    claim: BaseClaimSchema.merge(
      z.object({
        lossOfUse: LossOfUseSchema,
      })
    ),
  })
)
export type LossOfUseAddedEvent = z.infer<typeof LossOfUseAddedEventSchema>
export const isLossOfUseAddedEvent = (evt: unknown): evt is LossOfUseAddedEvent =>
  LossOfUseAddedEventSchema.safeParse(evt).success

export const LossOfUseCanceledEventSchema = LossOfUseEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.lossOfUseCanceled),
  })
)
export type LossOfUseCanceledEvent = z.infer<typeof LossOfUseCanceledEventSchema>

export const LossOfUsePendingEventSchema = LossOfUseEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.lossOfUsePending),
  })
)
export type LossOfUsePendingEvent = z.infer<typeof LossOfUsePendingEventSchema>

export const LossOfUseApprovedEventSchema = LossOfUseEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.lossOfUseApproved),
  })
)
export type LossOfUseApprovedEvent = z.infer<typeof LossOfUseApprovedEventSchema>

export const LossOfUseDeniedEventSchema = LossOfUseEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.lossOfUseDenied),
  })
)
export type LossOfUseDeniedEvent = z.infer<typeof LossOfUseDeniedEventSchema>

export const LossOfUseReceiptAddedEventSchema = LossOfUseEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.lossOfUseReceiptAdded),
    claim: LossOfUseEventSchema.shape.claim.merge(
      z.object({
        lossOfUse: LossOfUseEventSchema.shape.claim.shape.lossOfUse.merge(
          z.object({
            receipt: LossOfUseReceiptSchema,
          })
        ),
      })
    ),
  })
)
export type LossOfUseReceiptAddedEvent = z.infer<typeof LossOfUseReceiptAddedEventSchema>
export const isLossOfUseReceiptAddedEvent = (evt: unknown): evt is LossOfUseReceiptAddedEvent =>
  LossOfUseReceiptAddedEventSchema.safeParse(evt).success

export const LossOfUseReceiptRemovedEventSchema = LossOfUseEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.lossOfUseReceiptRemoved),
    claim: LossOfUseEventSchema.shape.claim.merge(
      z.object({
        lossOfUse: LossOfUseEventSchema.shape.claim.shape.lossOfUse.merge(
          z.object({
            receipt: LossOfUseReceiptRemovedSchema,
          })
        ),
      })
    ),
  })
)
export type LossOfUseReceiptRemovedEvent = z.infer<typeof LossOfUseReceiptRemovedEventSchema>
export const isLossOfUseReceiptRemovedEvent = (evt: unknown): evt is LossOfUseReceiptRemovedEvent =>
  LossOfUseReceiptRemovedEventSchema.safeParse(evt).success
