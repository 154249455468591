import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import {
  ClaimNumberSchema,
  ExternalSystemIdSchema,
  ExternalSystemRecordSchema,
} from '../model/index.js'
import { BaseHeaderSchema } from './base.js'

const RemoveExternalSystemIdSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    key: ExternalSystemIdSchema,
  }),
  headers: BaseHeaderSchema,
  response: z.object({
    claimNumber: ClaimNumberSchema,
    externalSystemIds: ExternalSystemRecordSchema,
  }),
}

export const RemoveExternalSystemIdRequestSchema = RemoveExternalSystemIdSchemas.params
  .merge(RemoveExternalSystemIdSchemas.body)
  .merge(RemoveExternalSystemIdSchemas.headers)

export type RemoveExternalSystemIdBodyRequest = z.infer<typeof RemoveExternalSystemIdSchemas.body>
export type RemoveExternalSystemIdHeaderRequest = z.infer<
  typeof RemoveExternalSystemIdSchemas.headers
>
export type RemoveExternalSystemIdRequest = z.infer<typeof RemoveExternalSystemIdRequestSchema>
export type RemoveExternalSystemIdResponse = z.infer<typeof RemoveExternalSystemIdSchemas.response>

export interface RemoveExternalSystemIdHandler
  extends Handler<RemoveExternalSystemIdRequest, RemoveExternalSystemIdResponse> {}

export const RemoveExternalSystemIdApiSchema: ApiSchema = RemoveExternalSystemIdSchemas
