import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema, InitialClaimActionsSchema } from '../model/index.js'
import { BaseHeaderSchema } from './base.js'

const UpdateInitialClaimActionsSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    initialClaimActions: InitialClaimActionsSchema.array(),
  }),
  headers: BaseHeaderSchema,
  response: z.object({
    claimNumber: ClaimNumberSchema,
    initialClaimActions: InitialClaimActionsSchema.array(),
  }),
}

export const UpdateInitialClaimActionsRequestSchema = UpdateInitialClaimActionsSchemas.params
  .merge(UpdateInitialClaimActionsSchemas.body)
  .merge(UpdateInitialClaimActionsSchemas.headers)

export type UpdateInitialClaimActionsBodyRequest = z.infer<
  typeof UpdateInitialClaimActionsSchemas.body
>
export type UpdateInitialClaimActionsHeaderRequest = z.infer<
  typeof UpdateInitialClaimActionsSchemas.headers
>
export type UpdateInitialClaimActionsRequest = z.infer<
  typeof UpdateInitialClaimActionsRequestSchema
>
export type UpdateInitialClaimActionsResponse = z.infer<
  typeof UpdateInitialClaimActionsSchemas.response
>

export interface UpdateInitialClaimActionsHandler
  extends Handler<UpdateInitialClaimActionsRequest, UpdateInitialClaimActionsResponse> {}

export const UpdateInitialClaimActionsApiSchema: ApiSchema = UpdateInitialClaimActionsSchemas
