import { EventBaseSchema, eventType } from '@eigtech/event-stream-types';
import z from 'zod';
export const serviceName = 'alert';
export const DBPartsSchema = z.object({
    PK: z.string(),
    SK: z.string(),
});
export const alertServiceEventType = (entity, eventName) => eventType(serviceName, entity, eventName);
export const AlertServiceEventBaseSchema = EventBaseSchema.merge(z.object({
    type: alertServiceEventType(),
}));
export const AlertLevelSchema = z.enum(['info', 'warn', 'error', 'critical']);
export const AlertEventSchema = AlertServiceEventBaseSchema.merge(z.object({
    type: alertServiceEventType(),
    metadata: z.object({
        service: z.string(),
    }),
    alert: z.string(),
    schemaVersion: z.literal(1),
}));
export const AlertEventRecordSchema = AlertEventSchema.merge(DBPartsSchema);
/**
 * ### InfoAlertEvent
 *
 * An event representing an informational alert.
 *
 * Example intentions:
 * * "Your batch processing has completed"
 * * "The hourly audit has come back clean"
 */
export const InfoAlertEventSchema = AlertEventSchema.merge(z.object({
    type: alertServiceEventType('info'),
}));
export const makeInfoAlertEvent = (eventName) => `alert:info:${eventName}`;
/**
 * ### WarnAlertEvent
 *
 * An event representing a warning alert.
 *
 * Example intentions:
 * * "We ran into an unexpected scenario, but were able to automatically recover. Everything should be fine now."
 * * "The service is doing something that has been deprecated"
 */
export const WarnAlertEventSchema = AlertEventSchema.merge(z.object({
    type: alertServiceEventType('warn'),
}));
export const makeWarnAlertEvent = (eventName) => `alert:warn:${eventName}`;
/**
 * ### ErrorAlertEvent
 *
 * An event representing an error alert.
 *
 * Example intentions:
 * * "We were unable to complete a non-critical operation"
 * * "Something was not processed correctly and will require a replay"
 * * Stuff that we don't have to get out of bed at 3AM for
 */
export const ErrorAlertEventSchema = AlertEventSchema.merge(z.object({
    type: alertServiceEventType('error'),
}));
export const makeErrorAlertEvent = (eventName) => `alert:error:${eventName}`;
/**
 * ### CriticalAlertEvent
 *
 * An event representing a critical alert.
 *
 * Example intentions:
 * * "A core service is not responding to requests"
 * * "A Large spice in errors has happened"
 * * "Prod is down"
 * * Stuff that we need to get out of bed at 3AM to go fix
 */
export const CriticalAlertEventSchema = AlertEventSchema.merge(z.object({
    type: alertServiceEventType('critical'),
}));
export const makeCriticalAlertEvent = (eventName) => `alert:critical:${eventName}`;
export const AlertEventProperties = AlertEventSchema.keyof().Values;
export const WatchedAlertEventConditionTypeSchema = z.object({
    key: z.literal(AlertEventProperties.type),
    value: z.string(),
});
export const WatchedAlertEventConditionIdSchema = z.object({
    key: z.literal(AlertEventProperties.id),
    value: z.string(),
});
export const WatchedAlertEventConditionMetadataSchema = z.object({
    key: z.literal(AlertEventProperties.metadata),
    value: z.string(),
    path: z.string(),
});
export const WatchedAlertEventConditionPartitionKeySchema = z.object({
    key: z.literal(AlertEventProperties.partitionKey),
    value: z.string(),
});
export const WatchedAlertEventConditionAlertJsonSchema = z.object({
    isJson: z.literal(true),
    path: z.string(),
    value: z.string(),
});
export const WatchedAlertEventConditionAlertNotJsonSchema = z.object({
    isJson: z.literal(false),
    regex: z.string(),
});
export const WatchedAlertEventConditionDetailsAlertSchema = z.discriminatedUnion('isJson', [
    WatchedAlertEventConditionAlertJsonSchema,
    WatchedAlertEventConditionAlertNotJsonSchema,
]);
export const WatchedAlertEventConditionAlertSchema = z.object({
    key: z.literal(AlertEventProperties.alert),
    detailConditions: WatchedAlertEventConditionDetailsAlertSchema,
});
export const WatchedAlertConditionsSchema = z.discriminatedUnion('key', [
    WatchedAlertEventConditionTypeSchema,
    WatchedAlertEventConditionIdSchema,
    WatchedAlertEventConditionMetadataSchema,
    WatchedAlertEventConditionPartitionKeySchema,
    WatchedAlertEventConditionAlertSchema,
]);
export const WatchedAlertRuleSchema = z.object({
    id: z.string(), // pk id
    label: z.string(), // readable label
    inclusiveConditions: WatchedAlertConditionsSchema.array(),
    exclusiveConditions: WatchedAlertConditionsSchema.array(),
});
export const isWatchedAlertEventConditionAlertJsonSchema = (event) => WatchedAlertEventConditionAlertJsonSchema.safeParse(event).success;
export const WatchedAlertEventConditionAlertNotJson = (event) => WatchedAlertEventConditionAlertNotJsonSchema.safeParse(event).success;
export const isWatchedAlertEventConditionAlert = (event) => WatchedAlertEventConditionAlertSchema.safeParse(event).success;
export const isWatchedAlertMetadataCondition = (event) => WatchedAlertEventConditionMetadataSchema.safeParse(event).success;
export const isWatchedAlertEventConditionId = (event) => WatchedAlertEventConditionIdSchema.safeParse(event).success;
export const isWatchedAlertEventConditionType = (event) => WatchedAlertEventConditionTypeSchema.safeParse(event).success;
export const isWatchedAlertEventConditionPartitionKey = (event) => WatchedAlertEventConditionPartitionKeySchema.safeParse(event).success;
export const AlertSubscriptionSchema = z.object({
    alertLevel: AlertLevelSchema,
    email: z.string(),
    subscriptionArn: z.string(),
});
