import { ClaimAssignableCanon } from '@eigtech/assignments-types'
import { Contact as ContactType } from '@eigtech/contacts-types'
import { formatUsPhoneToHtml } from '@eigtech/contacts-util'
import { getCornKernels } from '@eigtech/shared-corn'
import { useGetAssignablesForContact } from '@eigtech/ui-shared-assignments'
import { DateTime, formatStringAsDate } from '@eigtech/ui-shared-dates'
import {
  Badge,
  Property as CProperty,
  ComposedCard,
  ComposedCardProps,
  ComposedModal,
  ComposedModalWrapperProps,
  DataSource,
  Divider,
  FancyLink,
  HStack,
  Heading,
  Icon,
  IconProps,
  List,
  ListItem,
  PropertyList,
  PropertyProps,
  Stack,
  StackProps,
  Tag,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
  forwardRef,
  getGoogleMapQueryLink,
} from '@eigtech/ui-shared-dave'
import { formatPhoneNumber } from '@eigtech/ui-shared-phone'
import { Link, useRoutesContext } from '@eigtech/ui-shared-router'
import { startCase, uniqBy } from 'lodash-es'
import { FaMapMarkedAlt } from 'react-icons/fa'
import { IoDocument as ClaimIcon } from 'react-icons/io5'
import { getContactName, getContactRoleLabel } from '../utils'

export type ContactDetailProps = {
  contact: ContactType
} & ComposedCardProps

export const ContactDetail = forwardRef<ContactDetailProps, 'div'>(function Contact(
  { contact, ...props },
  ref
) {
  return (
    <ComposedCard ref={ref} {...props} heading={<ContactHead contact={contact} />}>
      <ContactBody contact={contact} />
    </ComposedCard>
  )
})

export function ViewContactModal({
  contact,
  ...modalProps
}: ComposedModalWrapperProps & { contact: ContactType }) {
  return (
    <ComposedModal {...modalProps} title={<ContactHead contact={contact} pr="8" />}>
      <ContactBody contact={contact} />
    </ComposedModal>
  )
}

export type ContactHeadProps = {
  contact: ContactType
  formatDate?: (dateTime: DateTime, format?: string | undefined) => string
} & StackProps

const ContactHead = forwardRef<ContactHeadProps, 'div'>(function ContactHead(
  { contact, formatDate = formatStringAsDate, ...props },
  ref
) {
  const { metadata } = contact

  return (
    <HStack ref={ref} justifyContent="space-between" {...props}>
      <Heading as="h3" size="sm">
        {getContactName(contact)}
      </Heading>

      <HStack>
        {!!metadata.removedOn && (
          <Tooltip label={formatDate(String(metadata.removedOn))} placement="top">
            <Badge colorScheme="red">Removed</Badge>
          </Tooltip>
        )}

        {!!metadata.dataSource && <DataSource>{metadata.dataSource}</DataSource>}
      </HStack>
    </HStack>
  )
})

const ContactBody = forwardRef<StackProps & Pick<ContactDetailProps, 'contact'>, 'div'>(
  function ContactBody({ contact, ...props }, ref) {
    const { data } = useGetAssignablesForContact(contact.contactId)
    const assignments = uniqBy(data ?? [], 'assignable.assignableId')

    const {
      addresses,
      communicationInformation,
      preferredMethodOfContact,
      roles,
      location,
      description,
    } = contact

    return (
      <Stack ref={ref} {...props} divider={<Divider />}>
        {!!addresses.length && (
          <PropertyList>
            {addresses.map((address, index) => (
              <Property
                key={address.rawAddress}
                data-key={address.addressType}
                label={
                  !!address.addressType
                    ? `${startCase(address.addressType)} Address`
                    : `Address ${index + 1}`
                }
                value={
                  <FancyLink
                    customIcon={MapIcon}
                    href={getGoogleMapQueryLink(address.rawAddress)}
                    isExternal
                    mb="0"
                    ml="2"
                  >
                    {address.rawAddress}
                  </FancyLink>
                }
              />
            ))}
          </PropertyList>
        )}

        {!!communicationInformation.length && (
          <PropertyList>
            <Property
              label="Preferred Contact Method"
              value={
                !!preferredMethodOfContact && preferredMethodOfContact !== 'unset'
                  ? preferredMethodOfContact
                  : 'Unknown'
              }
            />
            {communicationInformation.map((item, index) => {
              const isEmail = !!item.emailAddress

              return (
                <Property
                  key={index}
                  data-key={index}
                  label={
                    isEmail
                      ? 'Email'
                      : `Phone (${
                          (item.telecommunicationUseCode ?? item.preferenceOrder === 0)
                            ? 'Primary'
                            : 'Unknown Type'
                        })`
                  }
                  value={
                    !!(item.emailAddress || item.unformattedTelephoneNumber) ? (
                      <FancyLink
                        href={
                          isEmail
                            ? `mailto:${item.emailAddress}`
                            : `tel:${formatUsPhoneToHtml(item.unformattedTelephoneNumber ?? '')}`
                        }
                      >
                        {isEmail
                          ? item.emailAddress
                          : formatPhoneNumber(item.unformattedTelephoneNumber ?? '')}
                      </FancyLink>
                    ) : (
                      'Unknown'
                    )
                  }
                />
              )
            })}
          </PropertyList>
        )}

        <PropertyList>
          <Property
            label="Role(s)"
            value={
              <Wrap>
                {roles.map(getContactRoleLabel).map((role) => (
                  <WrapItem key={role}>
                    <Tag>{role}</Tag>
                  </WrapItem>
                ))}
              </Wrap>
            }
          />

          {!!location && (
            <Property
              label="Location"
              value={
                <FancyLink
                  href={getGoogleMapQueryLink({ lat: location.latitude, long: location.longitude })}
                  isExternal
                >
                  {location.latitude}, {location.longitude}
                </FancyLink>
              }
            />
          )}

          {!!description && <Property label="Description" value={description} />}
        </PropertyList>

        <PropertyList>
          <Property label="XactNet ID" value={contact.metadata.xactnetId ?? 'None'} />
        </PropertyList>

        <PropertyList>
          <Property label="Description" value={contact.description || 'None'} />
        </PropertyList>

        <PropertyList>
          <Property
            label="Assignments"
            value={
              <List>
                {assignments?.map((assignment) => (
                  <ListItem
                    key={`${assignment.assignable.assignableId}${assignment.assignable.assigneeRelationship}`}
                  >
                    {assignment.assignable.type === 'claim' ? (
                      <ClaimLink claimInfo={assignment.assignable} />
                    ) : assignment.assignable.type === 'job' ? (
                      <Text>{assignment.assignable.job.jobId}</Text>
                    ) : (
                      'Unknown'
                    )}
                  </ListItem>
                ))}
              </List>
            }
          />
        </PropertyList>
      </Stack>
    )
  }
)

function ClaimLink({ claimInfo }: { claimInfo: ClaimAssignableCanon }) {
  const { claimDetail } = useRoutesContext()

  const { resourceId: claimNumber } = getCornKernels(claimInfo.assignableId)

  return (
    <HStack>
      <ClaimIcon />
      {!!claimDetail ? (
        <Link params={{}} search={{}} to={claimDetail(claimNumber)}>
          {claimNumber}
        </Link>
      ) : (
        <Text>{claimNumber}</Text>
      )}
    </HStack>
  )
}

const Property = forwardRef<PropertyProps, 'dl'>(function Property(props, ref) {
  return <CProperty ref={ref} labelWidth="52" {...props} />
})

const MapIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon ref={ref} {...props} as={FaMapMarkedAlt} />
))
