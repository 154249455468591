import { ContactSchema } from '@eigtech/contacts-types'
import { z } from 'zod'
import { ClaimNumberSchema } from '../model/base.js'
import { PublicationTargetsSchema, PublishableClaimSchema } from '../model/publishable.js'
import { ClaimEventSchema, ClaimEventType, claimEventType } from './claim.js'

export const PublicationEventSchema = ClaimEventSchema.merge(
  z.object({
    claim: PublishableClaimSchema,
  })
)

export const MarkedForPublicationEventInputSchema = z.object({
  claimNumber: ClaimNumberSchema,
  requestActor: z.string(),
  policyHolder: ContactSchema,
  publicationTarget: PublicationTargetsSchema,
})

export const ClaimMarkedForPublicationEventSchema = PublicationEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.markedForPublication),
    claim: PublishableClaimSchema,
  })
)

export type PublicationEvent = z.infer<typeof PublicationEventSchema>
export type ClaimMarkedForPublicationEvent = z.infer<typeof ClaimMarkedForPublicationEventSchema>
export type ClaimMarkedForPublicationEventInput = z.infer<
  typeof MarkedForPublicationEventInputSchema
>
