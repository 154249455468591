import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { ClaimStatusLaxSchema } from '../../aggregates/claimStatus.js'

/** @deprecated */
export const UpsertClaimStatusRequestBodySchema = ClaimStatusLaxSchema.omit({ id: true })
/** @deprecated */
export const UpsertClaimStatusRequestHeaderSchema = ApiLambdalerAuthHeaderSchema
/** @deprecated */
export const SetStatusRequestSchema = UpsertClaimStatusRequestBodySchema.merge(
  UpsertClaimStatusRequestHeaderSchema
)
/** @deprecated */
export const UpsertClaimStatusResponseSchema = z.void()

/** @deprecated */
export type UpsertClaimStatusRequestBody = z.infer<typeof UpsertClaimStatusRequestBodySchema>

/** @deprecated */
export type UpsertClaimStatusRequest = z.infer<typeof SetStatusRequestSchema>
/** @deprecated */
export type UpsertClaimStatusResponse = z.infer<typeof UpsertClaimStatusResponseSchema>

/** @deprecated */
export interface UpsertClaimStatusHandler
  extends Handler<UpsertClaimStatusRequest, UpsertClaimStatusResponse> {}
