import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobIdSchema } from '../../base/ids.js'
import { JobDetailsSchema } from '../../base/jobDetails.js'

export const GetJobDetailsInternalApiSchema = {
  params: z.object({
    jobId: JobIdSchema,
  }),
  response: z.object({ job: JobDetailsSchema }),
}

export const GetJobDetailsInternalRequestSchema = GetJobDetailsInternalApiSchema.params

export type GetJobDetailsInternalRequest = z.infer<typeof GetJobDetailsInternalRequestSchema>
export type GetJobDetailsInternalRequestPath = z.infer<typeof GetJobDetailsInternalApiSchema.params>
export type GetJobDetailsInternalResponse = z.infer<typeof GetJobDetailsInternalApiSchema.response>

export interface GetJobDetailsInternalHandler
  extends Handler<GetJobDetailsInternalRequest, GetJobDetailsInternalResponse> {}
