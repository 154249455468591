import z from 'zod'
import {
  ParsedAssignmentExport,
  ParsedCustomDocumentExport,
  ParsedEstimateExport,
  ParsedNoteExport,
  ParsedStatusExport,
} from '../ediExport/index.js'
import { VeriskEDIEventSchema, veriskEdiServiceEventType } from './service.js'

export const VeriskEDIExportEventSchema = VeriskEDIEventSchema.merge(
  z.object({
    type: veriskEdiServiceEventType('export'),
    export: z.unknown(),
  })
)
export type VeriskEDIExportEvent = z.infer<typeof VeriskEDIExportEventSchema>

// #region parsed exports
export type VeriskEDIExportAssignmentReceivedEvent = VeriskEDIExportEvent & {
  type: `veriskEDI:export:assignmentReceived`
  export: ParsedAssignmentExport
}

export type VeriskEDIExportEstimateReceivedEvent = VeriskEDIExportEvent & {
  type: `veriskEDI:export:estimateReceived`
  export: ParsedEstimateExport
}

export type VeriskEDIExportCustomDocumentReceivedEvent = VeriskEDIExportEvent & {
  type: `veriskEDI:export:customDocumentReceived`
  export: ParsedCustomDocumentExport
}

export type VeriskEDIExportNoteReceivedEvent = VeriskEDIExportEvent & {
  type: `veriskEDI:export:noteReceived`
  export: ParsedNoteExport
}

export type VeriskEDIExportStatusReceivedEvent = VeriskEDIExportEvent & {
  type: `veriskEDI:export:statusReceived`
  export: ParsedStatusExport
}
// #endregion parsed exports

// #region processing
export interface VeriskEdiExportProcessingStartedEvent extends VeriskEDIExportEvent {
  type: 'veriskEDI:export:veriskEdiExportProcessingStarted'
  metadata: {
    service: 'verisk-edi-interface'
    eventKey: string
  }
  export: { key: string; bucket: string }
}

export interface VeriskEdiExportProcessingSucceededEvent extends VeriskEDIExportEvent {
  type: 'veriskEDI:export:veriskEdiExportProcessingSucceeded'
  metadata: {
    service: 'verisk-edi-interface'
    eventKey: string
  }
  export: { key: string; bucket: string }
}
// #endregion processing
