import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema } from '../model/base.js'

const IsClaimPublishableSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  response: z.object({ publishable: z.boolean(), message: z.string().optional() }),
}

export const IsClaimPublishableRequestSchema = IsClaimPublishableSchemas.params

export type IsClaimPublishableRequest = z.infer<typeof IsClaimPublishableRequestSchema>
export type IsClaimPublishableResponse = z.infer<typeof IsClaimPublishableSchemas.response>

export interface IsClaimPublishableHandler
  extends Handler<IsClaimPublishableRequest, IsClaimPublishableResponse> {}

export const IsClaimPublishableApiSchema: ApiSchema = IsClaimPublishableSchemas
