import { Handler } from 'aws-lambda'
import z from 'zod'
import { ImportStatusesSchema } from './importStatus.js'
import { ClaimNumberSchema } from '@eigtech/claims-v2-types'

export const XaAssignmentImportStatusApiSchema = {
  query: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  response: z.record(ClaimNumberSchema, ImportStatusesSchema),
}

export type XaAssignmentImportStatusRequest = z.infer<
  typeof XaAssignmentImportStatusApiSchema.query
>
export type XaAssignmentImportStatusResponse = z.infer<
  typeof XaAssignmentImportStatusApiSchema.response
>

export interface XaAssignmentImportStatusHandler
  extends Handler<XaAssignmentImportStatusRequest, XaAssignmentImportStatusResponse> {}
