import { Handler } from 'aws-lambda'
import z from 'zod'
import { VediImportSchema } from '../vediImport.js'

export const RetryClaimImportApiSchema = {
  body: z.object({
    vediImport: VediImportSchema.passthrough(),
    overrides: z.object({
      forceSend: z.boolean(),
    }),
  }),
  params: z.object({
    claimNumber: z.string(),
  }),
  response: z.object({
    result: VediImportSchema,
  }),
}

export const RetryClaimImportRequestSchema = RetryClaimImportApiSchema.params.merge(
  RetryClaimImportApiSchema.body
)
export type RetryClaimImportRequest = z.infer<typeof RetryClaimImportRequestSchema>
export type RetryClaimImportRequestBody = z.infer<typeof RetryClaimImportApiSchema.body>
export type RetryClaimImportResponse = z.infer<typeof RetryClaimImportApiSchema.response>
export type RetryClaimImportHandler = Handler<RetryClaimImportRequest, RetryClaimImportResponse>
