import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { ClaimSchema } from '../../aggregates/claim.js'
import { ClaimStatusCanonSchema } from '../../aggregates/claimStatus.js'
import { LossDetailsSchema } from '../../aggregates/lossDetails.js'
import { LossLocationSchema } from '../../aggregates/lossLocation.js'

/** @deprecated */
export const UpsertClaimRequestBodySchema = z.object({
  claim: ClaimSchema.omit({
    lossDetailsId: true,
    lossLocationId: true,
  }),
  lossDetails: LossDetailsSchema.omit({ id: true, claimNumber: true }),
  lossLocation: LossLocationSchema.omit({ id: true, claimNumber: true }),
  statusEvents: ClaimStatusCanonSchema.omit({ id: true, claimNumber: true }).array(),
})

/** @deprecated */
export const UpsertClaimRequestSchema = UpsertClaimRequestBodySchema

/** @deprecated */
export const UpsertClaimResponseSchema = z.void()

/** @deprecated */
export type UpsertClaimRequestBody = z.infer<typeof UpsertClaimRequestBodySchema>
/** @deprecated */
export type UpsertClaimRequest = z.infer<typeof UpsertClaimRequestSchema>
/** @deprecated */
export type UpsertClaimResponse = z.infer<typeof UpsertClaimResponseSchema>
/** @deprecated */
export interface UpsertClaimHandler extends Handler<UpsertClaimRequest, UpsertClaimResponse> {}
