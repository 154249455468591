import { CriticalAlertEventSchema, ErrorAlertEvent, WarnAlertEvent } from '@eigtech/alerts-types'
import { NoteCornSchema } from '@eigtech/notes-types'
import { z } from 'zod'
import { VediImportType, VediImportTypeSchema } from '../ediImport/index.js'

export const VediImportProcessingFailedAlertSchema = CriticalAlertEventSchema.merge(
  z.object({
    type: z.literal('alert:critical:vediImportProcessingFailed'),
    metadata: z.object({
      claimNumber: z.string(),
      service: z.literal('verisk-edi-interface'),
      importType: VediImportTypeSchema,
    }),
  })
)

export const VediNoteImportProcessingFailedAlertSchema =
  VediImportProcessingFailedAlertSchema.merge(
    z.object({
      metadata: VediImportProcessingFailedAlertSchema.shape.metadata.merge(
        z.object({
          importType: z.literal(VediImportType.note),
          noteId: NoteCornSchema,
        })
      ),
    })
  )

export const VeriskClaimAssignmentFailedAlertSchema = VediImportProcessingFailedAlertSchema.merge(
  z.object({
    metadata: VediImportProcessingFailedAlertSchema.shape.metadata.merge(
      z.object({
        importType: z.literal(VediImportType.assignment),
      })
    ),
  })
)

export const VeriskClaimAssignRecipientFailedAlertSchema =
  VediImportProcessingFailedAlertSchema.merge(
    z.object({
      metadata: VediImportProcessingFailedAlertSchema.shape.metadata.merge(
        z.object({
          importType: z.literal(VediImportType.assignRecipient),
          xactnetId: z.string(),
        })
      ),
    })
  )

export type VediImportProcessingFailedAlert = z.infer<typeof VediImportProcessingFailedAlertSchema>
export type VediNoteImportProcessingFailedAlert = z.infer<
  typeof VediNoteImportProcessingFailedAlertSchema
>
export type VeriskClaimAssignmentFailedAlert = z.infer<
  typeof VeriskClaimAssignmentFailedAlertSchema
>
export type VeriskClaimAssignRecipientFailedAlert = z.infer<
  typeof VeriskClaimAssignRecipientFailedAlertSchema
>

export interface VeriskClaimsProjectorFailedAlertEvent extends ErrorAlertEvent {
  type: `alert:error:veriskClaimsProjectorFailed`
  metadata: {
    service: 'verisk-edi-interface'
  }
}

export interface VeriskEdiExportProcessingFailedAlertEvent extends ErrorAlertEvent {
  type: 'alert:error:veriskEdiExportProcessingFailed'
  metadata: {
    service: 'verisk-edi-interface'
    eventKey: string
  }
}

export interface VeriskEdiArchiveImportFailedAlertEvent extends ErrorAlertEvent {
  type: 'alert:error:veriskEdiArchiveImportFailed'
  metadata: {
    service: 'verisk-edi-interface'
  }
}

export interface VeriskEdiExportWarnAlertEvent extends WarnAlertEvent {
  type: 'alert:warn:veriskEdiExportWarnAlert'
  metadata: {
    service: 'verisk-edi-interface'
  }
}
