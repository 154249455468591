import z from 'zod'
import {
  BaseClaimSchema,
  BaseLossDetails,
  BaseLossDetailsSchema,
  ClaimSeveritySchema,
  LocationSchema,
} from '../model/base.js'
import { ClaimEventSchema, ClaimEventType, claimEventType } from './claim.js'

const pickLossDetail = (detail: keyof BaseLossDetails) => {
  const requiredDetail: Partial<Record<keyof BaseLossDetails, true | undefined>> = {
    [detail]: true,
  }
  return BaseLossDetailsSchema.required(requiredDetail).pick(requiredDetail)
}

const CatCodeSchema = pickLossDetail('catCode')
const DateOfLossSchema = pickLossDetail('dateOfLoss')
const LossDetailsLossDescriptionSchema = pickLossDetail('lossDescription')
const LossDetailsLossTypeSchema = pickLossDetail('lossType')
const LossDetailsSeveritySchema = pickLossDetail('severity')

// This is just to check whether *some* loss details were updated
export const LossDetailsUpdatedSchema = z.union([
  CatCodeSchema,
  DateOfLossSchema,
  LossDetailsLossDescriptionSchema,
  LossDetailsLossTypeSchema,
  LossDetailsSeveritySchema,
])

export const CatCodeUpdatedEventSchema = ClaimEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.catCodeUpdated),
    claim: BaseClaimSchema.merge(
      z.object({
        lossDetails: CatCodeSchema,
      })
    ),
  })
)

export const DateOfLossUpdatedEventSchema = ClaimEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.dateOfLossUpdated),
    claim: BaseClaimSchema.merge(
      z.object({
        lossDetails: DateOfLossSchema,
      })
    ),
  })
)

export const LossDescriptionUpdatedEventSchema = ClaimEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.lossDescriptionUpdated),
    claim: BaseClaimSchema.merge(
      z.object({
        lossDetails: LossDetailsLossDescriptionSchema,
      })
    ),
  })
)

export const LossLocationUpdatedEventSchema = ClaimEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.lossLocationUpdated),
    claim: BaseClaimSchema.merge(
      z.object({
        lossLocation: LocationSchema,
      })
    ),
  })
)

export const LossSeverityUpdatedEventSchema = ClaimEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.lossSeverityUpdated),
    claim: BaseClaimSchema.merge(
      z.object({
        lossDetails: LossDetailsSeveritySchema,
      })
    ),
  })
)

export const LossTypeUpdatedEventSchema = ClaimEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.lossTypeUpdated),
    claim: BaseClaimSchema.merge(
      z.object({
        lossDetails: LossDetailsLossTypeSchema,
      })
    ),
  })
)

export type CatCodeUpdatedEvent = z.infer<typeof CatCodeUpdatedEventSchema>
export type DateOfLossUpdatedEvent = z.infer<typeof DateOfLossUpdatedEventSchema>
export type LossDescriptionUpdatedEvent = z.infer<typeof LossDescriptionUpdatedEventSchema>
export type LossLocationUpdatedEvent = z.infer<typeof LossLocationUpdatedEventSchema>
export type LossSeverityUpdatedEvent = z.infer<typeof LossSeverityUpdatedEventSchema>
export type LossTypeUpdatedEvent = z.infer<typeof LossTypeUpdatedEventSchema>
export type LossSeverity = z.infer<typeof ClaimSeveritySchema>
