import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobIdSchema } from '../../base/ids.js'
import { JobDetails, JobDetailsSchema } from '../../base/jobDetails.js'

export const GetJobDetailsApiSchema = {
  params: z.object({
    jobId: JobIdSchema,
  }),
  headers: ApiLambdalerAuthHeaderSchema.pick({ requestActor: true, requestToken: true }).required({
    requestActor: true,
    requestToken: true,
  }),
  response: z.object({ job: JobDetailsSchema }),
}

export const GetJobDetailsRequestSchema = GetJobDetailsApiSchema.params.merge(
  GetJobDetailsApiSchema.headers
)

export type GetJobDetailsRequest = z.infer<typeof GetJobDetailsRequestSchema>
export type GetJobDetailsRequestPath = z.infer<typeof GetJobDetailsApiSchema.params>
export type GetJobDetailsRequestHeaders = z.infer<typeof GetJobDetailsApiSchema.headers>
export type GetJobDetailsResponse = { job: Partial<JobDetails> } // cannot partial a discriminated union

export interface GetJobDetailsHandler
  extends Handler<GetJobDetailsRequest, GetJobDetailsResponse> {}
