import { z } from 'zod'
import { EventBaseSchema } from '@eigtech/event-stream-types'
import { ParsedAssignmentExport, XAAssignmentDoc } from '../ediExport/assignment.js'
import { ParsedCustomDocumentExportSchema } from '../ediExport/customDocument.js'
import { ParsedNoteExport, ParsedNoteExportSchema } from '../ediExport/note.js'
import { ParsedContact } from '../ediExport/parsedContact.js'
import { ParsedStatusExportSchema } from '../ediExport/status.js'
import {
  VediAssignRecipientImportSchema,
  VediAssignmentImport,
  VediAssignmentImportSchema,
  VediImportState,
  VediImportType,
  VediImportTypeSchema,
  VediNoteImportSchema,
  VediStatusImportSchema,
} from '../ediImport/vediImport.js'
import { VeriskEDIEvent, VeriskEDIEventSchema, veriskEdiServiceEventType } from './service.js'

const publicationEventType = <V extends string | undefined>(eventName?: V) =>
  veriskEdiServiceEventType('publication', eventName)

const ConfirmedImportSchema = z.object({ importState: z.literal(VediImportState.confirmed) })

export const VediPublishConfirmedEventSchema = VeriskEDIEventSchema.merge(
  z.object({
    type: publicationEventType('importConfirmed'),
    metadata: EventBaseSchema.shape.metadata.and(
      z.object({
        importType: VediImportTypeSchema,
      })
    ),
    publication: z.object({
      vediExport: z.unknown(),
      vediImport: z.unknown(),
    }),
  })
)
export type VediPublishConfirmedEvent = z.infer<typeof VediPublishConfirmedEventSchema>

// #region Note
export const VediNotePublishConfirmedEventSchema = VediPublishConfirmedEventSchema.merge(
  z.object({
    metadata: VediPublishConfirmedEventSchema.shape.metadata.and(
      z.object({
        importType: z.literal(VediImportType.note),
      })
    ),
    publication: z.object({
      vediExport: ParsedNoteExportSchema,
      vediImport: VediNoteImportSchema.merge(ConfirmedImportSchema),
    }),
  })
)
export type VediNotePublishConfirmedEvent = z.infer<typeof VediNotePublishConfirmedEventSchema>

/** @deprecated **/
export interface VeriskNotePublishConfirmedEvent extends VeriskEDIEvent {
  type: 'veriskEDI:publication:notePublishConfirmed'
  metadata: {
    transactionId: string
    noteId: string
  }
  note: ParsedNoteExport
}
// #endregion Note

// #region AssignRecipient
export const VediAssignRecipientPublishConfirmedEventSchema = VediPublishConfirmedEventSchema.merge(
  z.object({
    metadata: VediPublishConfirmedEventSchema.shape.metadata.and(
      z.object({
        importType: z.literal(VediImportType.assignRecipient),
      })
    ),
    publication: z.object({
      vediExport: ParsedStatusExportSchema,
      vediImport: VediAssignRecipientImportSchema.merge(ConfirmedImportSchema),
    }),
  })
)
export type VediAssignRecipientPublishConfirmedEvent = z.infer<
  typeof VediAssignmentImportPublishConfirmedEventSchema
>
/** @deprecated **/
export interface VeriskClaimAssignmentPublishConfirmedEvent extends VeriskEDIEvent {
  type: 'veriskEDI:publication:claimAssignmentPublishConfirmed'
  metadata: {
    transactionId: string
    xactnetId: string
  }
  contact: ParsedContact
}
// #endregion AssignRecipient

// #region Assignment
export const VediAssignmentImportPublishConfirmedEventSchema =
  VediPublishConfirmedEventSchema.merge(
    z.object({
      metadata: VediPublishConfirmedEventSchema.shape.metadata.and(
        z.object({
          importType: z.literal(VediImportType.assignment),
        })
      ),
      publication: z.object({
        // vediExport: ParsedAssignmentExportSchema,
        vediExport: z.unknown(), // TODO: convert a lot of types to schemas...
        vediImport: VediAssignmentImportSchema.merge(ConfirmedImportSchema),
      }),
    })
  )
export type VediAssignmentImportPublishConfirmedEvent = z.infer<
  typeof VediAssignmentImportPublishConfirmedEventSchema
> & {
  publication: {
    vediExport: ParsedAssignmentExport
    vediImport: VediAssignmentImport & { importState: 'confirmed' }
  }
}

/** @deprecated **/
export interface VeriskAssignmentPublishConfirmedEvent extends VeriskEDIEvent {
  type: 'veriskEDI:publication:xaAssignmentPublishConfirmed'
  metadata: {
    claimNumber: string
  }
  assignment: XAAssignmentDoc
}
// #endregion Assignment

// #region Document
export const VediDocumentImportPublishConfirmedEventSchema = VediPublishConfirmedEventSchema.merge(
  z.object({
    metadata: VediPublishConfirmedEventSchema.shape.metadata.and(
      z.object({
        importType: z.literal(VediImportType.document),
      })
    ),
    publication: z.object({
      vediExport: ParsedCustomDocumentExportSchema,
      vediImport: VediAssignmentImportSchema.merge(ConfirmedImportSchema),
    }),
  })
)
export type VediDocumentImportPublishConfirmedEvent = z.infer<
  typeof VediDocumentImportPublishConfirmedEventSchema
>

// #region Status
export const VeriskStatusPublishConfirmedEventSchema = VediPublishConfirmedEventSchema.merge(
  z.object({
    metadata: VediPublishConfirmedEventSchema.shape.metadata.and(
      z.object({
        importType: z.literal(VediImportType.status),
      })
    ),
    publication: z.object({
      vediExport: ParsedStatusExportSchema,
      vediImport: VediStatusImportSchema.merge(ConfirmedImportSchema),
    }),
  })
)
export type VeriskStatusPublishConfirmedEvent = z.infer<
  typeof VeriskStatusPublishConfirmedEventSchema
>
// #endregion Status
