import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { DocumentPublicationRecordSchema } from '../aggregates/index.js'

// get document Publications
export const GetDocumentPublicationsApiSchema = {
  headers: ApiLambdalerAuthHeaderSchema,
  params: z.object({
    entityId: CornSchema,
    documentCorn: z.string(),
  }),
  response: DocumentPublicationRecordSchema.omit({
    PK: true,
    SK: true,
  }).array(),
} satisfies ApiSchema

export const GetDocumentPublicationsRequestSchema = GetDocumentPublicationsApiSchema.params.merge(
  GetDocumentPublicationsApiSchema.headers
)

export type GetDocumentPublicationsRequest = z.infer<typeof GetDocumentPublicationsRequestSchema>
export type GetDocumentPublicationsResponse = z.infer<
  typeof GetDocumentPublicationsApiSchema.response
>
export type GetDocumentPublicationsHandler = Handler<
  GetDocumentPublicationsRequest,
  GetDocumentPublicationsResponse
>
