import z from 'zod'

import { BaseClaimSchema } from '../model/index.js'
import { ClaimEventSchema, ClaimEventType, claimEventType } from './claim.js'

export const ClaimCreatedEventSchema = ClaimEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.claimCreated),
    claim: BaseClaimSchema,
  })
)

export type ClaimCreatedEvent = z.infer<typeof ClaimCreatedEventSchema>
